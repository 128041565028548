<template>
  <div>
    <b-container v-show="loading" class="text-center">
      <b-spinner variant="primary"></b-spinner>
    </b-container>
    <b-table
      :fields="fields"
      v-show="!loading && items.length > 0"
      :items="items"
    >
    </b-table>
  </div>
</template>
<script>
import { get_user_input_sales, format_date } from "./actions";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      fields: [
        {
          key: "date",
          label: "Date",
          tdClass: "text-muted",
          formatter: (value) => {
          return this.format_date(value);
        },
        },
        "item",
        "unit",
        "unit_price",
        "qty",
        "amount",
      ],
    };
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },
    url() {
      return this.baseUrl + this.project_id + "/input-sales/user";
    },
  },
  methods: {
    get_user_input_sales,
    format_date,
  },
  mounted() {
    this.get_user_input_sales();
  },
};
</script>
