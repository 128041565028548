<template>
  <div>
    <vl-map
      :load-tiles-while-animating="true"
      :load-tiles-while-interacting="true"
      data-projection="EPSG:4326"
      style="height: 400px"
    >
    <vl-view :zoom="zoom" :center="center" :rotation="rotation"></vl-view>
      <vl-layer-tile>
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>
    <vl-feature v-for="farm,idx in farms" :key="idx">
        <vl-geom-polygon :coordinates="[farm.coordinates]"></vl-geom-polygon>
    </vl-feature>
    </vl-map>
  </div>
</template>
<script>
import {Shape} from "../../"
export default {
    props:{
        /**
         * @property {{centroid:number[],acreage:number}} farms
         */
        farms:Array
    },
    data(){
        return {
        zoom: 20,
        center: [32.5825,0.3476],
        rotation: 0,
        }
    },
    mounted(){
        this.getCenterPosition();
    },
    methods:{
        getCenterPosition(){
            const points = this.farms.map(el=>el.centroid);
            const shape = new Shape(points);
            this.center=shape.centroid;
            // return shape.centroid;
        },
    }
};
</script>