import { axiosApiInstance } from "@/plugins/axios";



function get_payment_info() {
  this.loading = true;

  let data = {
    farmer_id: this.info.farmer_id,
  };
  axiosApiInstance({
    url: this.url+"/payments/user/details/",
    method: "get",
    params: data,
  })
    .then((res) => {
      this.loading=true;
      let details = res.data.data;
      for (var key in details) {
        this.info[key] = details[key];
      }
      
      let contact = details.momo_phone?
      details.momo_phone:details.phonenumber;
      contact?this.info.contact=contact:0;
      let method = (details['metadata,method'])?details['metadata,method'].toLowerCase():'mobile';
      method=method?method.toLowerCase():method;
      this.select_bank=details.bank;
      if(method==='bank'){
        this.loading = true;
        this.select_method=method;

      }else{
        this.select_method='mobile';
        if (details.momo_first_name){
          this.info.account_name =  
          details.momo_first_name + " " + details.momo_last_name;
        }else{
          this.info.account_name=this.info.farmer_name;
        }
        
      }
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
  // axios.get
}

function get_banks() {
  this.loadingbanks = true;
  axiosApiInstance({
    url: this.url+"/payments/user/banks/",
    method: "get",
  })
    .then((res) => {
      this.banks = res.data.data;

      this.loadingbanks = false;
    })
    .catch((error) => {
      console.log(error);
      this.loadingbanks = false;
    });
  // axios.get
}

function save_farmer_amount() {
  this.loading = true;
  this.payment = {};
  let names = this.info.account_name.split(' ');
  let first_name = names[0];
  let other_names = names.slice(1).join(' ');

  let phone = Number(this.info.contact).toString();

  let code = phone.startsWith('256')?'':'256';
  let contact = code+phone;

  this.payment_info =
    this.select_method == "mobile"
      ? {
          first_name: first_name,
          last_name: other_names,
          phonenumber: contact,
          payment_method: "Mobile Money",
          "metadata,method": "money",
          "metadata,phone": contact,
        }
      : {
          account_name: this.info.account_name,
          account_number: this.info.account_number,
          phonenumber: contact,
          payment_method: "bank",
          bank: this.select_bank,
          "metadata,method": "bank",
          "metadata,phone": contact,
          destination_branch_code: this.banks.filter(
            (bank) => String(bank.value) === String(this.select_bank)
          )[0].destination_branch_code
        };

  this.loading = true;

  this.payment_not_started = false;

  let data = {
    payment_method: this.payment_info,
    farmer_id: this.info.farmer_id,
    amount: this.info.payment,
    farmers: {},
  };
  data.farmers[this.info.farmer_id] = this.info.payment;
  axiosApiInstance({
    url: this.url+"/payments/user/",
    method: "put",
    data: JSON.stringify(data),
  })
    .then((res) => {
      console.log(res.data.status);
      this.loading = false;

      this.success = true;
      this.$vToastify.success('Successfully Saved 👍🏿');
      setTimeout(() => {
        this.success = false;
        let payload = Object.assign(this.payment_info,this.info)
        payload= Object.assign(payload,{select_method:this.select_method});
        this.$emit("EditPaymentInfoSuccess",
        payload);
        this.$bvModal.hide("payment-info");
      }, 100);
    })
    .catch((error) => {
      this.loading = false;
      this.error = true;
      console.log(error);
      this.$vToastify.error('Failed to save');
      setTimeout(() => {
        this.$router.push('#');
        this.$emit("EditPaymentInfoSuccess");
        this.$bvModal.hide("payment-info");
      }, 5000);
    });
}


export {

  get_payment_info,
  get_banks,
  save_farmer_amount,
};
