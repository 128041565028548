<template>
  <b-container>
    <div class="row mb-5">
      <div class="col-md-7">
        <div v-if="isLoadingProfile">
          <b-row class="d-flex pt-3 justify-content-center mb-3">
            <span class="font-weight-light text-muted"
              >Loading...&nbsp;&nbsp;</span
            >
            <b-spinner variant="secondary" label="Loading..."></b-spinner>
          </b-row>
        </div>
        <table v-show="!isLoadingProfile" class="table-summary">
          <thead>
            <th><h4 class="title">Bio info</h4></th>
            <th>
              <!-- Using value -->
              <b-button
                class="btn btn-sm btn-outline-primary"
                v-b-modal.edit-profile
                v-analytics:click="[
                  'edit_farmer_info',
                  { element: 'Suppliers' },
                ]"
                :disabled="!can('modify', 'suppliers')"
                ><i class="uil uil-pencil"></i> Edit</b-button
              >
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Full Name</td>
              <td>{{ farmerProfile.farmer_name | capitalize}}</td>
            </tr>

            <tr>
              <td>Age</td>
              <td>{{ getAge(farmerProfile.farmer_dob) }}</td>
            </tr>

            <tr>
              <td>Gender</td>
              <td>{{ farmerProfile.farmer_gender | capitalize }}</td>
            </tr>
            <tr>
              <td>Agent</td>
              <td>{{ farmerAgent | capitalize }}</td>
            </tr>
            <div v-if="farmerProfile['has_metadata']">
              <tr v-for="item in farmerProfile['metadata']" :key="item.label">
                <td>{{ item.label }}</td>
                <td>{{ item | fmt_meta }}</td>
              </tr>
            </div>
          </tbody>

          <thead>
            <th><h4 class="title">Contact info</h4></th>
            <th>
              <button
                class="btn btn-sm btn-outline-primary"
                v-b-modal.edit-profile
                v-analytics:click="[
                  'edit_farmer_info',
                  { element: 'Suppliers' },
                ]"
                :disabled="!can('modify', 'suppliers')"
              >
                <i class="uil uil-pencil"></i> Edit
              </button>
            </th>
          </thead>
          <tbody>
            <tr>
              <td>Phone Numer</td>
              <td>{{ farmerProfile.farmer_phone_number }}</td>
            </tr>

            <tr v-show="false">
              <td>Email</td>
              <td>mukisa56@gmail.com</td>
            </tr>

            <tr>
              <td>Location</td>
              <td>{{ farmerProfile.farmer_district }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="col-md"></div>
      <div class="col-md-4 position-relative">
        <div v-show="false" class="shadow p-3 bg-white rounded sticky-">
          <h5>Certifications</h5>
          <p class="m-0">
            <i class="uil uil-shield-check mr-2 text-success"></i>Organic
            (089382)
          </p>
          <button
            class="btn btn-outline-primary btn-sm mt-2"
            v-b-modal="'edit-certificates'"
          >
            <i class="uil uil-pen"></i> Edit
          </button>
        </div>
      </div>

    </div>
    <b-row v-if="!isLoadingProfile">
      <b-col
        ><b-button
          variant="danger"
          size="sm"
          v-b-modal.delete-profile
          v-analytics:click="['delete_farmer', { element: 'Suppliers' }]"
          :disabled="!can('modify', 'suppliers')"
        >
          <i class="uil uil-bin"></i>Delete Profile
        </b-button></b-col
      >
    </b-row>
    <b-modal id="delete-profile" size="sm" hide-footer hide-header>
      <h5 class="text-center">Delete Item</h5>
      <p class="text-center">
        Do you want to delete this item?<br />You will not be able to recover
        it.
      </p>
      <div>
        <b-button
          type="reset"
          variant="secondary"
          class="center float-left"
          size="sm"
          v-analytics:click="['cancel_delete_farmer', { element: 'Suppliers' }]"
          @click="onReset"
          >Cancel</b-button
        >
        <b-button
          type="submit"
          variant="primary"
          class="ml-2 float-right"
          size="sm"
          @click="deleteFarmerProfile()"
        >
          <b-spinner
            v-if="isDeleting"
            small
            label="Small Spinner"
            v-analytics:click="[
              'confirm_delete_farmer',
              { element: 'Suppliers' },
            ]"
          ></b-spinner>
          Yes, Delete
        </b-button>
      </div>
    </b-modal>
    
    <edit-bio></edit-bio>
    <edit-contact></edit-contact>
    <edit-certificates></edit-certificates>
    <edit-profile
      @EditProfileSuccess="onProfileEdited"
      :editProfileForm="{...profilecp,...{farmer_gender:title(profilecp.farmer_gender)}}"
      :key="'edit'+editkey"
      :groups="groups"
      :group_id="farmerProfile.farmer_group | group_val"
    ></edit-profile>
  </b-container>
</template>
<script>
import moment from "moment";
import { bus } from "@/main.js";
import { can } from "@/modules/auth/utils.js";
import { axiosApiInstance } from "@/plugins/axios";
import EditBio from "../modals/EditBio";
import EditProfile from "./edit_profile/EditProfile.vue";
import EditContact from "../modals/EditContact";
import Utils from "../utils.js";
import EditCertificates from "../modals/EditCertificates";
import { list_groups } from "@/modules/suppliers/subroutes/farmer_groups/api.calls";
import { format_options } from "@/components/add_farmer/ui.utils";

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    EditBio,
    EditContact,
    EditCertificates,
    EditProfile,
  },
  mixins: [Utils],
  data() {
    return {
      farmerProfile: {},
      isLoadingProfile: false,
      groups: [],
      farmerAgent: "",
      isDeleting: false,
      editkey:0,
      profilecp:{farmer_id:''},
    };
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
    url() {
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  methods: {
    getAge(dateString) {
      if (dateString !== "NA") {
        const age = moment().diff(dateString, "years");
        return age.toLocaleString();
      } else {
        return "NA";
      }
    },
    onProfileEdited(status) {
      this.isEditing = status;
      this.getFarmersDetails();
    },
    onReset() {
      this.$bvModal.hide("delete-profile");
    },
    title(value){
      return value?value.charAt(0).toUpperCase() + value.slice(1):'-';
    },
    getFarmersDetails() {
      this.isLoadingProfile = true;
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .get(baseUrl + `/api/v3/farmers/single/${this.item.farmer_id}`)
        .then((result) => {
          if (result.data.success) {
            const farmerProfile = result.data.data.farmer_profile;
            this.farmerProfile = this.profilecp=farmerProfile;
            this.farmerAgent = result.data.data.village_agent;
            this.isUploading = false;
            this.isEditing = false;
            bus.$emit("edit-farmer-profile", this.farmerProfile);
            this.$emit("SuccessfulEdit", this.farmerProfile.farmer_name);
          }
          this.isLoadingProfile = false;
        })
        .catch((error) => {
          this.isEditing = false;
          this.isLoadingProfile = false;
          this.error = error;
        });
    },
    deleteFarmerProfile() {
      this.isDeleting = true;
      var self = this;
      const baseUrl = process.env.VUE_APP_BACKEND;
      const farmerId = this.farmerProfile.farmer_id;

      axiosApiInstance
        .delete(
          baseUrl + `/api/v3/farmers/delete/${this.project_id}/${farmerId}`
        )
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            bus.$emit("edit-status", this.isDeleting);
            this.onReset();
            this.$bvModal.hide("delete-profile");
            this.$bvModal.hide("farmer-view");
          }
        })
        .catch((error) => {
          this.isDeleting = false;
          this.error = error;
          // alert(this.error);
          self.$vToastify.error("something went wrong please try again...");
        });
    },
    can,
  },
  mounted() {
    this.getFarmersDetails();
    list_groups().then((data) => {
      this.groups = data.map(format_options);
    });
  },
};
</script>
<style lang="scss" scoped>
.sticky- {
  top: 100px;
}
</style>
