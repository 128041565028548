<template>
  <b-container>
     
    <div class="tabs nav-pills d-flex align-items-middle border-bottom">
      <a
        @click="atab = 'pinfo'"
        class="nav-link"
        :class="(atab == 'pinfo' ? 'active' : '')"
        >Personal Info</a>
      <a class="nav-link"  @click="atab = 'gardens'" :class="(atab == 'gardens' ? 'active' : '')">Gardens</a>
      <a class="nav-link" v-show="false" @click="atab = 'contracts'" :class="(atab == 'contracts' ? 'active' : '')">Contracts</a>
      <a class="nav-link"  @click="atab = 'payinfo'" :class="(atab == 'payinfo' ? 'active' : '')">Payment Info</a>
    </div>
    <div class="content">
<personal-info :item="item" v-show="atab=='pinfo'"></personal-info>
<gardens :item="item" v-if="atab=='gardens'"></gardens> 
<contracts :item="item" :farmer_name="item" v-show="atab=='contracts'"></contracts>
<payment-info :info="item" v-show="atab=='payinfo'"></payment-info>
    </div>
  </b-container>
</template>

<script>
  import PersonalInfo from './subcomponents/PersonalInfo'
  import Gardens from './subcomponents/gardens/Gardens'
  import Contracts from './subcomponents/Contracts'
  import PaymentInfo from './subcomponents/payment_info/PaymentInfo'
export default {
  props:{
    item:{
      type:Object,
      default:()=>{
        return {};
      }
    },
  },
components:{
  PersonalInfo,
  Gardens,
  Contracts,
  PaymentInfo,
},
  data() { return{
    atab: "pinfo"
  }

  }
};
</script>

<style lang="scss" scoped>
  .tabs{
padding: 16px 0px;
background-color:#fff;
position:sticky;
top:0;
z-index:20;

  }
</style> 