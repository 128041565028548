import {FARMER_PROD} from "./actions.js"
import {axiosApiInstance} from "@/plugins/axios.js"
/**
 * 
 * @typedef {Object} TonnesAvailable 
 * @property {number} quantity_supplied
 * @property {string} date_received
 */
/**
 * 
 * @typedef {Object} BulkedSchema
 * @property {number} quantity_supplied
 * @property {string} date_received
 * @property {number} amount_used
 * @property {string} value_chain
 */

const ProduceModel = {
    /**
     * 
     * @param {number} project_id 
     * @param {string} farmer_id 
     * @param {string} path
     */
    async find(project_id,farmer_id,path){
        const res = await axiosApiInstance({
            url:FARMER_PROD(project_id,farmer_id)+"/"+path
        });
        return res.data;
    },
    
}
/**
 * 
 * @param {number} project_id 
 * @param {string} farmer_id 
 * @returns {Promise<Array<TonnesAvailable>>}
 */
async function get_produce_available(project_id,farmer_id){
    const data = await ProduceModel.find(project_id,farmer_id,"available"); 
    return data;
}
/**
 * 
 * @param {number} project_id 
 * @param {string} farmer_id 
 * @returns {Promise<Array<BulkedSchema>>}
 */
async function get_produce_entries(project_id,farmer_id){
    const data =  await ProduceModel.find(project_id,farmer_id,"entries")
    return data;
}
/**
 * @typedef  DateFilter
 * @property {string} start_date
 * @property {string} end_date
 */

/**
 * 
 * @param {number} project_id 
 * @param {string} farmer_id 
 * @param {function():DateFilter} cb
 * @returns {Promise<{available:Array<TonnesAvailable>,entries:Array<BulkedSchema>}>} 
 */
async function get_by_date(project_id,farmer_id,cb){
    const res = await axiosApiInstance({url:FARMER_PROD(project_id,farmer_id)+"/by_date",params:cb()})
    return res.data;
}
export {get_produce_available,get_produce_entries,get_by_date}