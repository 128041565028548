<template>
  <div>
    <!-- The modal -->
    <b-modal
      centered
      id="edit-certificates"
      title="Edit Certifications"
      ok-title="Save Changes"
    >
      <label class="control-label">
        <b-form-checkbox v-model="checked" name="check-button" switch>
          Certified <b>({{ checked }})</b>
        </b-form-checkbox></label
      >
      <b-form-checkbox v-model="checked_1" name="check-button" switch>
        Organic Certified <b>({{ checked_1 }})</b>
      </b-form-checkbox>
      <div v-show="checked || checked_1">
        <label>Certification Number</label>
        <input class="form-control" placeholder="" />
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      checked: false,
      checked_1: false,
    };
  },
};
</script>
