import { axiosApiInst } from "@/plugins/axios";
function giveInput(payload) {
    // console.log(dataObject);
    this.loadingInputSubmission = true;
    axiosApiInst({
      url: this.url,
      method: "post",
      data: payload,
    })
      .then((response) => {
        this.loadingInputSubmission = false;
        if (response.data.success === true) {
          this.$vToastify.success(response.data.message);
          this.$emit("AdditionSuccess");
          this.$bvModal.hide(this.modal_id);
        } else {
          this.$vToastify.error(response.data.message);
          // alert(response.data.message);
        }
      })
      .catch((error) => {
        this.loadingInputSubmission = false;
        this.$vToastify.error("failed to save");
        this.$vToastify.error(error.response.data.message);
        
      });
  }
export {giveInput}