var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"farmer-view","size":"xl","hide-footer":"","hide-header":"","no-close-on-esc":"","no-close-on-backdrop":"","body-class":"body p-0","no-fade":"","centered":""}},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-3 left p-0 border-right"},[_c('div',{staticClass:"farmer-card"},[_c('table',[_c('td',[_c('div',{staticClass:"img"},[_c('b-avatar',{directives:[{name:"show",rawName:"v-show",value:(!_vm.photo_exists),expression:"!photo_exists"}],attrs:{"icon":"person"}},[_vm._v(_vm._s(_vm.name[0]))]),_c('b-img',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.photo_exists),expression:"photo_exists"}],attrs:{"width":"50px","height":"50px","src":'https://storage.googleapis.com/ezyagric-media' +_vm.profile.farmer_photo,"rounded":"circle","alt":""}},'b-img',_vm.imgProps,false))],1)]),_c('td',[_c('h4',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.name))]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.profile.farmer_district),expression:"profile.farmer_district"}],staticClass:"m-0 text-muted text-small"},[_vm._v(_vm._s(_vm.profile.farmer_district)+", "+_vm._s(_vm.profile.farmer_village))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"text-small"},[_c('i',{staticClass:"text-success uil uil-shield-check"}),_vm._v(" Organic ")])])])]),_c('div',{staticClass:"tabs",attrs:{"role":"tablist"}},[_c('a',{staticClass:"tab-link",class:{ active: _vm.tab == 'dashboard' },on:{"click":function($event){_vm.tab = 'dashboard';
            _vm.tabname = 'DASHBOARD';}}},[_vm._v("Dashboard")]),_c('a',{staticClass:"tab-link",class:{ active: _vm.tab == 'produce' },on:{"click":function($event){_vm.tab = 'produce';
            _vm.tabname = 'PRODUCE';}}},[_vm._v("Produce")]),_c('a',{staticClass:"tab-link",class:{ active: _vm.tab == 'farm_inputs' },on:{"click":function($event){_vm.tab = 'farm_inputs';
            _vm.tabname = 'FARM INPUTS';}}},[_vm._v("Farm Inputs")]),_c('a',{staticClass:"tab-link",class:{ active: _vm.tab == 'training' },on:{"click":function($event){_vm.tab = 'training';
            _vm.tabname = 'TRAINING';}}},[_vm._v("Training")]),_c('a',{staticClass:"tab-link",class:{ active: _vm.tab == 'payments' },on:{"click":function($event){_vm.tab = 'payments';
            _vm.tabname = 'PAYMENTS';}}},[_vm._v("Payments")]),_c('a',{staticClass:"tab-link",class:{ active: _vm.tab == 'profile' },on:{"click":function($event){_vm.tab = 'profile';
            _vm.tabname = 'FARMER DETAILS';}}},[_vm._v("Farmer Details")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"tab-link",class:{ active: _vm.tab == 'reports' },on:{"click":function($event){_vm.tab = 'reports'}}},[_vm._v("Reports")]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"tab-link",class:{ active: _vm.tab == 'monitoring' },on:{"click":function($event){_vm.tab = 'monitoring';
            _vm.tabname = 'MONITORING';}}},[_vm._v("Monitoring")])])]),_c('div',{staticClass:"col-9 p-0"},[_c('b-row',{staticClass:"m-0"},[_c('b-col',[_c('div',{staticClass:"page-head d-flex align-items-center h-100"},[_c('h5',{staticClass:"page-title m-0"},[_vm._v(_vm._s(_vm.tabname))])])]),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"cols":"auto"}},[_c('div',{staticClass:"d-flex align-items-center h-100"},[_c('button',{staticClass:"btn-opt primary"},[_c('b-icon-arrow-counterclockwise'),_vm._v(" Refresh ")],1),_c('button',{staticClass:"btn-opt primary"},[_c('b-icon-printer'),_vm._v(" Print ")],1),_c('button',{staticClass:"btn-opt primary"},[_c('b-icon-book'),_vm._v(" Export ")],1)])]),_c('b-col',{staticClass:"col-sm-1",attrs:{"col-auto":""}},[_c('button',{staticClass:"close p-3",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.close}},[_c('b-icon-x')],1)])],1),_c('div',{staticClass:"overflow-scroll modal-content-holder"},[(_vm.tab == 'dashboard')?_c('dashboard',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.tab == 'produce')?_c('produce',{attrs:{"item":_vm.item},on:{"GotoPayments":_vm.payments_tab}}):_vm._e(),(_vm.tab == 'farm_inputs')?_c('farm-inputs',{attrs:{"item":_vm.item},on:{"SuccessfulChange":_vm.loadFarmInputs}}):_vm._e(),(_vm.tab == 'payments')?_c('payments',{key:_vm.payments_view,attrs:{"item":_vm.item},on:{"GotoProduce":_vm.produce_tab}}):_vm._e(),(_vm.tab == 'reports')?_c('reports',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.tab == 'monitoring')?_c('monitoring',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.tab == 'training')?_c('training',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.tab == 'profile')?_c('farmer-info',{attrs:{"farmer_name":_vm.name,"item":_vm.item}}):_vm._e()],1)],1),_c('pay-farmer',{on:{"SuccessfulPayment":_vm.reload}}),_c('pay-cash',{on:{"SuccessfulPayment":_vm.reload}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }