import { required,helpers,minValue, requiredIf,} from "vuelidate/lib/validators";
const paymentTypes={
    mobile:"mobile",
    cash:"cash",
    bank:"bank"
};

const phoneValidator = helpers.regex('phoneValidator',/[0-9]{10}/);
let rvalidator={
    value_chain:{
      required
    },
    supplier_type:{
      required
    },
    variety: {
      required
    },
    gross_weight:{
        required,
        minValue:minValue(1)
    },
    net_weight:{
        required,
        minValue:minValue(1)
    },
    unit_price:{
        required
    },
    method:{
        required
    },
    reg_fname:{
        required:requiredIf((data)=>{
           return data.method==paymentTypes.mobile;
        })
    },
    reg_lname:{
        required:requiredIf((data)=>{
            return data.method==paymentTypes.mobile;
        })
    },
    reg_number:{
        required:requiredIf((data)=>{
           return data.method==paymentTypes.mobile; 
        }),
        phoneValidator
    },
    bank:{
        required:requiredIf((data)=>{
            return data.method==paymentTypes.bank;
        })
    },
    account_name:{
        required:requiredIf((data)=>{
            return data.method==paymentTypes.bank;
        })
    },
    account_number:{
        required:requiredIf((data)=>{
            return data.method==paymentTypes.bank;
        })
    },

    foreign_matter:{
        required
    },
    moisture_content:{
        required
    },
    district:{
      required
    },
    contact:{
      required,
      phoneValidator
    },
    receipt_number:{
        required
    }
  };
export {rvalidator}