<template>
  <div>
    <b-container>
      SESSIONS ATTENDED
      <div class="pt-3" v-show="items.length > 0">
        <b-table 
        :items="items" 
        :fields="fields"
        @row-clicked="view_training"
        > </b-table>
      </div>
      <div class="pt-3 text-center" v-show="loading">
        <b-spinner></b-spinner>
      </div>
      <div class="pt-3" v-show="!loading && items.length===0">
        {{ item.name }} has not attended a training yet..
      </div>
    </b-container>
  </div>
</template>
<script>
import { get_user_training_sessions,view_training, format_date } from "./actions";
export default {
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      items: [],
      fields: [
        "topic",
        {
          key: "date",
          label: "Date",
          formatter: (value) => {
            return this.format_date(value);
          },
        },
      ],
      loading:false
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },

    url_prefix() {
      return this.$store.state.url_prefix;
    },
    url() {
      return (
        this.baseUrl +
        this.$store.state.project_id +
        "/training/users/attended_sessions"
      );
    },
  },
  methods: {
    get_user_training_sessions,
    format_date,
    view_training,
  },
  mounted() {
    this.get_user_training_sessions();
  },
};
</script>
