import { axiosApiInstance } from "@/plugins/axios.js";
import { BASE_URL } from "./api.constants.js";
/**
 * 
 * @typedef FarmerGroup 
 * @property {string} id
 * @property {string} name
 * @property {{name:string}} contact 
 */

/**
 * 
 * @returns {Promise<Array<FarmerGroup>>}
 */
async function list_groups() {
    const res = await axiosApiInstance({
      url: BASE_URL
    });
    return res.data;
}
export {list_groups}