<template>
  <b-container>
    <b-container v-show="loadingPendingPayments">
      <b-row class="d-flex justify-content-center mb-3">
        <span class="font-weight-light text-muted"
              >Loading {{item.name}}'s Pending Payments...&nbsp;&nbsp;</span
            >
        <b-spinner
          
          label="Large Spinner"
        ></b-spinner>
      </b-row>
    </b-container>
    <b-row
        v-show="!loading"
      class="p-3 bg-light"
      :key="idx"
      v-for="(each, idx) in pending_balance"
    >
      <!-- <payment :user_id="farmer_id"></payment>
       -->
      <b-col>
        <h6 class="subtitle">Pending Payment</h6>
        <h5>
          {{ currency }}
          <span class="text-success">
            &nbsp;{{ each.payment.toLocaleString() }}
          </span>
        </h5>
      </b-col>
      <b-col>
        <b-row >
          <a class="col text-muted p-3"
            ><u><small @click="go_to_produce">Break Down</small></u></a
          >
          <div class="col p-3">
            <b-button class="btn-sm" @click="pay_farmer" variant="success" v-analytics:click="['pay_farmer',{element:'Suppliers'}]">
              <b-icon-check></b-icon-check>
              <small>Pay Now</small>
            </b-button>
          </div>

          <div class="col p-3">
            <b-button @click="pay_cash" class="btn-sm" variant="outline-muted">
              <b-icon-check></b-icon-check>
              <small>Record Cash Payment</small>
            </b-button>
          </div>
        </b-row>
      </b-col>
    </b-row>

    <h6 class="subtitle mt-4">PAYMENT HISTORY</h6>
    <b-container v-show="loading">
      <b-row class="d-flex justify-content-center mb-3">
        <span class="font-weight-light text-muted"
              >Loading {{ item.name }}'s Payment History...&nbsp;&nbsp;</span
            >
        <b-spinner
          label="Large Spinner"
        ></b-spinner>
      </b-row>
    </b-container>
    <b-table
      v-show="items.length > 0"
      :items="items"
      :fields="fields"
      sticky-header="50vh"
      head-variant="light"
    >
    <template #cell(time)="data">
      {{ exact_time(data.item.created_at) }}
    </template>
    <template #cell(amount)="data">
        {{ format_num(data.item.amount) }}
    </template>
    <template #cell(payment_state)="data">
        {{ data.item.payment_state?data.item.payment_state:'scheduled' }}
    </template>
    </b-table>
    <b-container v-show="!loading && !(items.length > 0)">
      <h6 class="text-muted text-justify">
        <small> No Previous Payments.</small>
      </h6>
    </b-container>

  </b-container>
</template>
<script>
// import Payment from "../payment/Payment.vue";

import {bus} from "@/main.js"
import { get_payment_data, 
exact_time,
get_user_amount,
format_num,
 } from "./actions.js";

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          farmer_id:''
        };
      },
    },
  },
  computed:{
    url(){
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  data() {
    return {
      fields: [
        { key: "tag", label: "Ref" },
        { key: "time", label: "Date" },
        { key: "amount", label: "Amount" },
        { key: "payment_method", label: "Method" },
        {key: 'payment_state', label:"Status"}
      ],
      user_id: "",
      items: [],
      amount: 0,
      farmer_id: "",
      currency: "UGX.",
      loading: false,
      pending_balance: [],
      loadingPendingPayments:false,
    };
  },
  methods: {
    get_payment_data,
    get_user_amount,
    format_num,
    exact_time,
    go_to_produce() {
      this.$emit("GotoProduce");
    },
    pay_farmer() {
      this.pending_balance[0]['pay_now']=true;
      bus.$emit("pay-farmer", this.pending_balance[0]);
    },
    pay_cash() {
      this.pending_balance[0]['pay_now']=true;
      bus.$emit("pay-cash", this.pending_balance[0]);
    },
    reload(){
        this.get_payment_data();
        this.get_user_amount();
    }
  },
  created() {
    this.name = this.item.name;
    this.farmer_id = this.item.farmer_id;
    this.get_payment_data();
    this.get_user_amount();
  },
};
</script>
