import { axiosApiInstance } from "@/plugins/axios.js"
import { FARMER_BASE, chartOptions } from "./defaults.js"
import { pluck } from "@/modules/utils.js"
// import { to_tonnes } from "../../../../modules/utils.js"
/**
 * 
 * @param {string} path 
 * @returns {Function}
 */
function set_subr(path) {
    /**
     * @param {number} project_id
     * @param {string} farmer_id
     */
    return (project_id, farmer_id) => {
        return FARMER_BASE + `/P-${project_id}` + `/${path}` + "/" + farmer_id
    }
}

const FARMER_PRODUCE = set_subr("fp_orders")
const FARMER_ADVANCE  = set_subr("advances")
export const FARMER_PROD = set_subr("produce");
/**
 * 
 * @param {Array<{crop:string,quantity:number}>} data 
 * @returns {Promise<Array<number>>}
 */
async function get_values(data) {
    return data.map(pluck("quantity_supplied"));
}
/**
 * 
 * @param {Array<{crop:string,quantity:number}>} data 
 * @returns {Promise<Array<string>>}
 */
async function get_labels(data) {
    return data.map(pluck("date_received"));
}
/**
 * 
 * @param {number} project_id 
 * @param {string} farmer_id 
 */
function get_produce_harvested(project_id, farmer_id) {
    axiosApiInstance({
        url: FARMER_PRODUCE(project_id, farmer_id) + "/timely_produce_harvested"
    }).then(res => {
        get_values(res.data.produce).then(vals => {
            this.series = [{ name: "Tonnes", data: vals }];
        });
        get_labels(res.data.produce).then(labels => {
            this.chartOptions = {
                ...chartOptions, xaxis: { categories: labels,title:{text:res.data.crop} }
            };
        });
    });
}
/**
 * 
 * @param {number} project_id 
 * @param {string} farmer_id 
 */
function get_advances(project_id,farmer_id){
    axiosApiInstance({
        url:FARMER_ADVANCE(project_id,farmer_id)+"/due"
    }).then(res=>{
        this.advances = res.data;
    });
}
/**
 * @param {number} project_id
 * @param {string} farmer_id 
 */
function get_average_acreage(project_id,farmer_id){
    axiosApiInstance({
        url:FARMER_PRODUCE(project_id,farmer_id) +"/average_acreage"
    }).then(res=>{
        this.average_acreage=parseFloat(res.data).toFixed(3);
    });
}
/**
 * 
 * @param {number} project_id 
 * @param {string} farmer_id 
 */
function get_fp_orders(project_id,farmer_id){
    axiosApiInstance({
        url:FARMER_PRODUCE(project_id,farmer_id)+"/all"
    }).then(res=>{
        this.items = res.data;
    });
}

export { get_produce_harvested,get_advances,get_average_acreage,get_fp_orders}