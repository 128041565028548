<template>
  <!-- Edit farmer profile Modal -->
  <b-modal id="edit-profile" title="Edit Farmer Info" size="lg" hide-footer>
    <div class="container-fluid">
      <b-form
        @submit.prevent="editFarmerProfile"
        @reset.prevent="onReset"
        v-if="show"
      >
      <b-row>
        <b-col>
          <b-form-group id="f-name" label="Name" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="editProfileForm.farmer_name"
            type="text"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group id="dob" label="Date of birth">
              <b-form-datepicker
                v-model="editProfileForm.farmer_dob"
                type="text"
                required
              >
              </b-form-datepicker>
            </b-form-group>

        <b-form-group id="gender" label="Gender" label-for="input-3">
              <b-form-select
                id="input-3"
                v-model="editProfileForm.farmer_gender"
                :options="gender"
                required
              ></b-form-select>
            </b-form-group>

        <b-form-group id="phone_no" label="Phone Number" label-for="input-4">
          <b-form-input
            id="input-4"
            v-model="editProfileForm.farmer_phone_number"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="district" label="District">
          <b-form-select
            v-model="editProfileForm.farmer_district"
            :options="districts"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group id="village" label="Village" label-for="input-6">
          <b-form-input
            id="input-6"
            type="text"
            v-model="editProfileForm.farmer_village"
            required
          ></b-form-input>
        </b-form-group>


        </b-col>
        <b-col>
          <b-form-group id="nin" label="National ID Number" label-for="n-id">
          <b-form-input
            id="n-id"
            v-model="editProfileForm.public_id_number"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="email" label="Email Address" label-for="mail">
          <b-form-input
            id="mail"
            v-model="editProfileForm.farmer_email"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="va_no" label="Village Agent" label-for="vano">
          <b-form-select
            v-model="editProfileForm.vaId"
            :options="villageAgents"
          ></b-form-select>
        </b-form-group>

        <b-form-group id="f-group" label="Farmer Group" label-for="coop">
          <b-form-select
            v-model="editProfileForm.farmer_group"
            :options="groups"
          >
          </b-form-select>
        </b-form-group>

        <b-form-group id="f-crop" label="Crop" label-for="val_chain">
          <b-form-select
            v-model="editProfileForm.value_chain"
            :options="crops"
          ></b-form-select>
        </b-form-group>

        </b-col>
      </b-row>
        
        

        <div class="float-right">
          <b-button
            type="reset"
            variant="secondary"
            v-analytics:click="[
              'cancel_farmer_changes',
              { element: 'Suppliers' },
            ]"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="ml-2"
            v-analytics:click="[
              'save_farmer_changes',
              { element: 'Suppliers' },
            ]"
          >
            <b-spinner v-if="isEditing" small label="Small Spinner"></b-spinner>
            Save Changes
          </b-button>
        </div>
      </b-form>
    </div>
  </b-modal>
</template>
<script>
import { axiosApiInstance } from "@/plugins/axios";
export default {
  props: {
    editProfileForm: {
      type: Object,
      default: () => {
        return {
          farmer_name: "",
          farmer_dob: "",
          farmemr_id:"",
          farmer_gender: null,
          farmer_phone_number: "",
          farmer_district: null,
          farmer_village: "",
          public_id_number: "",
          farmer_email: "",
          farmer_group: null,
          value_chain: "",
          vaId: "",
        };
      },
    },
    groups: {
      type: Array,
      default: () => {
        return [];
      },
    },
    group_id: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    return {
      gender: [{ text: "Select Gender", value: null }, "Male", "Female"],
      districts: [],
      show: true,
      isEditing: false,
      message: "",
      villageAgents: [],
      crops: [],
    };
  },

  mounted() {
    this.setDistrictsToSelect();
    this.setAgentsToSelect();
    this.setCropsToSelect();
  },

  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },

  methods: {
    onReset() {
      this.$bvModal.hide("edit-profile");
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    editFarmerProfile() {
      this.isEditing = true;
      var self = this;
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .post(
          baseUrl + `/api/v3/farmers/update/${this.editProfileForm.farmer_id}`,
          this.editProfileForm
        )
        .then((result) => {
          if (result.data.success) {
            this.message = result.data.message;
            this.onReset();
            this.$emit("EditProfileSuccess", this.isEditing);
            this.isEditing = false;
          } else if (!result.data.success) {
            this.isEditing = false;
            this.error = result.data.message;
            // alert(this.error);
            self.$vToastify.error("something went wrong please try again...");
          }
        })
        .catch((error) => {
          this.isEditing = false;
          this.error = error;
          // alert(this.error);
          self.$vToastify.error("something went wrong please try again...");
        });
    },

    setDistrictsToSelect() {
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .get(baseUrl + `/api/v3/farmers/districts`)
        .then((result) => {
          if (result.data.success) {
            result.data.data.map((each) => {
              this.districts.push(each.district);
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },

    capitalize(string) {
      return string?string.charAt(0).toUpperCase() + string.slice(1):'-';
    },

    setAgentsToSelect() {
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .get(baseUrl + `/api/v3/village-agents/${this.projectId}`)
        .then((result) => {
          if (result.data.success) {
            result.data.data.map((each) => {
              this.villageAgents.push({ text: each.va_name, value: each.vaId });
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },

    setCropsToSelect() {
      const baseUrl = process.env.VUE_APP_BACKEND;

      axiosApiInstance
        .get(baseUrl + `/api/v3/farmers/crops`)
        .then((result) => {
          if (result.data.success) {
            result.data.data.map((each) => {
              this.crops.push(each.crop);
            });
          }
        })
        .catch((error) => {
          this.error = error;
        });
    },
  },
};
</script>

<style></style>
