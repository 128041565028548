<template>
  <b-modal id="add-advance-item" title="Add Item" hide-footer>
    <b-form @submit.prevent="add_item">
      <b-container>
        <b-form-group
          :label="module+' Type'"
          label-for="advancetype"
          :description="'Select '+module+' Type'"
        >
          <b-form-select
            v-model="item.advance_type"
            :options="advance_types"
            @change="getInputNames"
            required
          ></b-form-select>
        </b-form-group>
        <b-button v-show="showAddInput" @click="routePage">Click this link to create an input</b-button>
        <b-form-group
          label="Item Name"
          label-for="item"
          description="Enter Item Name"
        >
          <b-form-input
            v-if="hideFields"
            v-model="inputName"
            type="text"
            required
          ></b-form-input>
          <!-- <vue-bootstrap-typeahead
                v-if="!hideFields"
                :data="inputsByCategory"
                v-model="inputName"
                size="lg"
                :serializer="s => s.name"
                @hit="getUnits()"
              ></vue-bootstrap-typeahead> -->
              <b-form-select
                v-if="!hideFields"
                v-model="inputName"
                :options="inputNameArray"
                @change="getUnits"
                required
              ></b-form-select>
        </b-form-group>
        <b-form-group label="Unit" label-for="unit" description="Select Unit">
          <b-form-select
            v-model="itemUnit"
            :options="units"
            @change="setUnitDetails"
            required
          ></b-form-select>
        </b-form-group>
        <b-row>
          <b-col> {{ currency }}&nbsp; {{ price }}&nbsp; per unit </b-col>
          <b-col v-show="hideFields"> {{ itemInterest }}%&nbsp; Interest </b-col>
          <b-col class="border-left">
            <span
              ><strong class="text-success">{{ units_available }}</strong>
              &nbsp;Units available
            </span>
          </b-col>
        </b-row>

        <b-form-group
          label="Quantity"
          label-for="quantity"
          :description="'Enter Quantity given as '+module"
        >
          <b-form-input id="quantity" v-model="item.quantity" type="number" @keyup="calulateTotal" required></b-form-input>
          <b-alert v-if="isError" variant="danger" show>
            The quantity exceeds the available stock
          </b-alert>
        </b-form-group>
        <div class="row">
          <div class="col-sm-12 float-right">
            <h5 class="text-danger">Total Amount</h5>
            <h4>{{ total_amount }}</h4>
          </div>
        </div>
        <b-row>
					<div class="col-sm-12">
          <b-button
            variant="primary"
            class="btn-sm float-right"
						type="submit"
            v-analytics:click="['add_advance_item',{element:'Suppliers'}]"
            :disabled="isError"
            >Add Item</b-button
          >
					</div>
        </b-row>
      </b-container>
    </b-form>
  </b-modal>
</template>
<script>
import axios from "axios";
import $ from 'jquery';
export default {
  props: {
    advance_items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    module: {
      type: String,
      default: ()=>{
        return '';
      }
    },
  },
  data() {
    return {
      item: {
        name: "",
        unit: "",
        advance_type: "",
        unit_index:0,
        price: 0,
        quantity: 0,
        amount:0
      },
      price: 0,
      units_available: 0,
      units: [{ text: "Select One", value: null }],
      advance_types: [{ text: "Select One", value: null }],
      currency: "UGX",
      total_amount: 0,
      advanceInputs:[],
      inputsByCategory:[],
      inputNameArray:[],
      inputName:null,
      isError: false,
      itemUnit:'',
      showAddInput: false,
      selectedInputsPrice:{},
      hideFields:false,
      itemInterest:0
    };
  },
  computed:{
    project_id(){
      return this.$store.state.project_id;
    }
  },
  methods: {
    routePage(){
      this.$router.push({path:"/settings/farm_input"});
    },
    add_item() {
      this.item.name = this.inputName;
      this.item.price = this.price;
      this.item.amount = this.total_amount;
      this.item.unit_index = this.itemUnit;
      // console.log(this.item);
      this.inputsByCategory = []
      if(this.item.name.length === 0 && this.item.price === 0 && this.item.amount === 0 
        && this.item.unit.length === 0 && this.item.advance_type.length === 0){
          this.$vToastify.error("Please ensure all fields have been entered");
        }
      else{
        this.advance_items.push(this.item);
        this.item = {
          name: "",
          unit: "",
          advance_type: "",
          unit_index:0,
          price: 0,
          quantity: "",
          amount:0
        },
        this.inputName=null;
        this.itemUnit=""
        this.units=[{ text: "Select One", value: null }];
        this.units_available = 0;
        this.price = 0;
        this.total_amount = 0;
        this.$bvModal.hide('add-advance-item');
      }
      
    },
    getAdvanceInputs(){
      var self = this;
      var categoryNames = [];
      // self.loadingInputs = true;      
      axios.get(process.env.VUE_APP_BACKEND+"/advances/get-inputs", {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
        params: {project_id: this.project_id}
      })
      .then((response) => {
        // data = response.data.data;
        self.advanceInputs = response.data.data;
        for (const x of self.advanceInputs){
          categoryNames.push(x.advance_type);
        }
        self.setAdvanceCategories(categoryNames);
        // self.loadingInputs = false;
        
      });
    },
    setAdvanceCategories(dataArray){
      var self = this
      $.each(dataArray, function(i, el){
          if($.inArray(el, self.advance_types) === -1) self.advance_types.push(el);
      });
      if(this.advance_types.length === 1){
        this.showAddInput = true;
      }
      else{
        this.showAddInput = false;
      }
    },
    getInputNames(){
      var category = this.item.advance_type;
      var self = this
      this.inputsByCategory = [];
      this.inputNameArray = [];
      $.each(self.advanceInputs, function(i, el){
          if(category === el.advance_type){
            self.inputsByCategory.push(el);
            self.inputNameArray.push(el.name)
          }
      });
      if(self.inputNameArray.length>0){
        self.inputNameArray.unshift({ text: "Select One", value: null })
      }else{
        self.inputNameArray.push({ text: "Category has no inputs", value: null })
      }
      if (category === "Cash"){
        this.hideFields = true;
        this.inputName = "Cash"
        this.getUnits();
      }
      else{
        this.hideFields = false;
      }
    },
    getUnits(){
      var self = this;
      this.selectedInputsPrice = {}
      this.units = [{ text: "Select One", value: null }];
      $.each(self.inputsByCategory, function(i, el){
          // if(self.inputName === el.name) self.units.push({text:el.units,value:i});
          if(self.inputName === el.name) {
            self.selectedInputsPrice = el;
            el.units.forEach((unit,index) => {
            self.units.push({text:unit,value:index});
          });}
      });
      // console.log("Units array: ", this.units);
      // console.log("selected input: ", this.selectedInputsPrice);
    },
    setUnitDetails(){
      var i = this.itemUnit;
      if(i === null){
        // alert("Please select an actual unit");
        this.$vToastify.error("Please select an actual unit");
        this.isError = true;
      }
      else{
        this.isError = false;
        this.item.unit = this.selectedInputsPrice.units[i];
        this.price = this.selectedInputsPrice.unit_price[i];
        this.units_available = this.selectedInputsPrice.available_stock[i];
        if("interest" in this.selectedInputsPrice){
          this.itemInterest = this.selectedInputsPrice.interest;
        }
        else{
          this.itemInterest = 0;
        }
      }
      // this.units_available = 0;
      // for (const x of this.inputsByCategory){
      //   if(this.item.unit == x.units){
      //     // console.log("Inside for loop: ",x);
      //     // console.log("the price: ",x.unit_price," the stock: ",x.available_stock);
      //     this.price = x.unit_price;
      //     this.units_available = x.available_stock;
      //   }
      // }
    },
    calulateTotal(){
      var costOfItem = 0;
      var newItemInterest = 0;
      if(this.item.quantity > this.units_available && this.hideFields !== true){
        // this.$vToastify.error("The quantity exceeds the available stock");
        $("#quantity").css('color', 'red');
        this.isError = true;
      }
      else{
        if(this.item.quantity === '0'){
          // alert("The quantity can not be 0");
          this.$vToastify.error("The quantity can not be 0");
          this.isError = true;
        }
        else{
          $("#quantity").css('color', '#000');
          this.isError = false;
          if (this.itemInterest > 0){
            costOfItem = this.item.quantity * this.price;
            newItemInterest = costOfItem * (this.itemInterest / 100)
            this.total_amount = costOfItem + newItemInterest;
            this.isError = false;
          }
          else{
            this.total_amount = this.item.quantity * this.price;
            this.isError = false;
          }
        }
      }
    },
  },
  mounted() {
    this.item = {};
    this.getAdvanceInputs();
  },

};
</script>
