<template>
  <b-container id="dashboard">
    <div class="page-head">
      <h5 class="sub-page-title">Gardens ({{ farms.length }})</h5>
    </div>
    <div v-if="isLoadingGardens">
      <b-row class="container mb-3">
        <span class="font-weight-light text-muted">Loading...&nbsp;&nbsp;</span>
        <b-spinner variant="secondary" label="Loading..."></b-spinner>
      </b-row>
    </div>
    <div v-if="!isLoadingGardens && farms.length==0">
      <b-row class="container">
        <span class="font-weight-light text-muted">No Gardens...&nbsp;&nbsp;</span>
      </b-row>
    </div>
    <div class="row">
      <div class="col-7">
        <!-- <div
          class="garden-item card shadow"
          v-for="(garden, i) in gardens"
          :key="i"
        > -->
        <div class="garden-item card shadow">
          <farmer-garden v-if="farms.length" :farms="farms"></farmer-garden>
          <div v-show="false" class="card-footer">
            <div class="row">
              <div class="col">
                <!-- <p class="m-0">
                  {{ garden.acreage }} Acres | {{ garden.location.village }},
                  {{ garden.location.district }}
                </p> -->
              </div>
              <div class="col-auto">
                <button
                  class="btn-icon uil uil-times"
                  title="Delete Garden"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="chart">
          <table class="w-100">
            <td>
              <apexchart
                v-show="false"
                width="120"
                height="120"
                type="donut"
                :options="options"
                :series="series"
              ></apexchart>
            </td>
            <td></td>
          </table>
        </div>
        <h5 v-show="false" class="subtitle">CROPS</h5>

        <span v-show="false" class="badge badge-light badge-lg">Maize</span>
        <span v-show="false" class="badge badge-light">Chia</span>
      </div>
    </div>
  </b-container>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import { get_gardens } from "../../../../api";
import { Farm } from "../../";

import FarmerGarden from "./FarmerGarden.vue";
export default {
  components: { apexchart: VueApexCharts, FarmerGarden },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      farms: [],
      isLoadingGardens: false,
      series: [5, 2],
      options: {
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        labels: ["Used", "Unused"],
        colors: ["#3F7BB6", "#C4C4C4"],
        plotOptions: {
          pie: {
            donut: {
              size: "85%",
              labels: {
                show: true,
                total: {
                  show: true,
                  label: "Used",
                  formatter: function(w) {
                    // console.log(w);
                    return w.globals.seriesTotals.reduce((i, total) => {
                      return total;
                    }, 0);
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  methods: {
    getGardens() {
      this.isLoadingGardens = true;
      get_gardens(this.item.farmer_id).then((data) => {
        this.farms = data.map((fm) => new Farm(fm));
        this.isLoadingGardens = false;
      });
    },
  },
  mounted() {
    this.getGardens();
  },
};
</script>
<style scoped lang="scss">
.garden-item {
  margin-bottom: 16px;
  overflow: hidden;
  .map {
    height: 250px;
    background-color: #fefefe;
  }
}

.badge {
  padding: 8px;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #899;
  margin-right: 8px;
}
</style>
