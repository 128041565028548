<template>
  <b-modal id="pay-farmer" size="lg" title="Payment" hide-footer>
    <b-row>
      <b-col>
        <b-container>
          <h5 v-show="paynow" class="text-secondary font-weight-light">
            Are you sure you want to initiate payment to
          </h5>
          <h6>
            <strong>{{ info.farmer_name }}</strong>
          </h6>
          <hr />
          <b-row>
            <b-col>
              <b-form-group
                label="Total Payment"
                label-for="totalpayment"
                :description="
                  currency +
                    (info.payment ? Number(info.payment).toLocaleString() : '0')
                "
              >
                <b-form-input
                  v-model="info.payment"
                  type="number"
                  :disabled="!can('create_schedule', 'payments')"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-row class="text-muted">Net balance</b-row>
              <b-row class="text-success"
                ><h4>
                  {{
                    currency +
                      Number(
                        info.pending_balance - info.payment
                      ).toLocaleString()
                  }}
                </h4></b-row
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-checkbox
                :disabled="!can('create_schedule', 'payments')"
                class="text-muted p-3"
                v-model="paynow"
              >
                <span v-if="pay_now">Make Payment Now</span>
                <span v-if="!pay_now">Edit Payment Info</span>
              </b-form-checkbox>
            </b-col>
            <br />
          </b-row>
          <b-row>
            <b-col v-show="!paynow">
              <b-button
                :disabled="!can('create_schedule', 'payments')"
                variant="outline-primary"
                @click="save_farmer_amount()"
                >Save Payment Amount</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col v-show="loading">
        <b-container v-show="loading">
          <b-row class="d-flex justify-content-center mb-3">
            <span class="font-weight-light text-muted"
              >Processing...&nbsp;&nbsp;</span
            >
            <b-spinner variant="secondary" label="Loading..."></b-spinner>
          </b-row>
        </b-container>
        <b-container v-show="error">
          <b-alert class="justify-content-center" variant="danger">Payment Failed!!!</b-alert>
              
        </b-container>
        <b-container v-show="success">
          <b-alert class="justify-content-center" variant="success" show>Successful!!</b-alert>
        </b-container>
      </b-col>

      <b-col v-show="paynow && can('view', 'payments') && !loading" class="border-left">
        <div>
          <b-form-group
            label="Payment Method"
            label-for="method"
            description="Select Payment Method type"
          >
            <b-form-select
              v-model="select_method"
              :options="methods"
              :disabled="!can('create_schedule', 'payments')"
              required
            ></b-form-select>
          </b-form-group>

          <div v-show="select_method == 'mobile'">
            <h6 class="">Mobile Details</h6>
            <b-form-group
              label="Name"
              label-for="name"
              description="Enter Recipient's Registered Name"
            >
              <b-form-input
                v-model="info.account_name"
                type="text"
                :disabled="!can('create_schedule', 'payments')"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Phone"
              label-for="number"
              description="Enter Recipient's Registered Number in the format 07xxxxxxx"
            >
              <b-form-input
                v-model="info.contact"
                type="number"
                :disabled="!can('create_schedule', 'payments')"
                required
              ></b-form-input>
            </b-form-group>
          </div>

          <div v-show="select_method == 'bank'">
            <h6 class="">Bank Details</h6>
            <b-form-group
              label="Account Name"
              label-for="acname"
              description="Enter Recipient's Account Name"
            >
              <b-form-input
                v-model="info.account_name"
                type="text"
                :disabled="!can('create_schedule', 'payments')"
                required
              ></b-form-input>
            </b-form-group>
            
            <b-row>
              <b-col>
                <b-form-group
              label="Account Number"
              label-for="acnumber"
              :disabled="!can('create_schedule', 'payments')"
              description="Enter Recipient's Account Number"
            >
              <b-form-input
                v-model="info.account_number"
                type="number"
                required
              ></b-form-input>
            </b-form-group>
              </b-col>
              <b-col>
            <b-form-group
              label="Phone"
              label-for="phone"
              description="Recipient's Phone Number"
            >
              <b-form-input
                v-model="info.contact"
                type="number"
                :disabled="!can('create_schedule', 'payments')"
                required
              ></b-form-input>
            </b-form-group>
            </b-col>
            
            
            
            </b-row>

            <b-form-group
              label="Bank"
              label-for="bank"
              v-if="!loadingbanks"
              description="Select your bank"
            >
              <b-form-select
                v-model="select_bank"
                :options="banks"
                :disabled="!can('create_schedule', 'payments')"
                required
              ></b-form-select>
            </b-form-group>
            <b-container v-show="loadingbanks">
              <b-row class="d-flex justify-content-center mb-3">
                <span class="font-weight-light text-muted"
                  >Loading Banks...&nbsp;&nbsp;</span
                >
                <b-spinner variant="secondary" label="Loading..."></b-spinner>
              </b-row>
            </b-container>

          
          </div>
        </div>

        <b-row v-show="payment_not_started && paynow && !loading">
          <b-col v-show="!verified">
            <small class="text-muted">Save for a later payment schedule</small>
            <div>
            <b-button
              :disabled="!can('create_schedule', 'payments')"
              variant="outline-primary"
              @click="save_farmer_amount()"
              >Save</b-button
            >
            </div>
          </b-col>
          <b-col v-show="verified">
            
            <b-form-group
              label="Pin"
              label-for="phone"
              description="Check your sms"
            >
              <b-form-input
                v-model="info.pin"
                type="password"
                :disabled="!can('approve', 'payments')"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-show="verified">
            <b-button
              
              :disabled="!can('approve', 'payments')"
              variant="success"
              class="float-right col"
              @click.prevent="pay_farmer"
              >
              <b-icon-check></b-icon-check>
              Pay Farmer</b-button
            >
            <br/>
            <b-row class="p-3">
            <b-button
              
              :disabled="!can('approve', 'payments') || waited==1"
              variant="outline-primary"
              class="float-right col"
              @click.prevent="send_verification"
              >
              <b-icon-envelope></b-icon-envelope>
              <small>Request Another Pin</small></b-button
            >
            </b-row>
          </b-col>
          <b-col v-if="pay_now" class="border-left" v-show="!verified && can('approve', 'payments')">
            <small v-show="can('approve', 'payments')" class="text-success float-right justify-content-end align-items-right">
              Request for a pin to make an instant Payment</small>
            <b-button
              
              :disabled="!can('approve', 'payments')"
              variant="outline-success"
              class="float-right"
              @click.prevent="send_verification"
              v-analytics:click="['request_pin_farmer',{element:'Suppliers'}]"
              >
              <b-icon-envelope></b-icon-envelope>
              <small>&nbsp;Request Pin</small></b-button
            >
          </b-col>
        </b-row>

        
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { bus } from "@/main.js";
import { save_farmer_amount,get_payment_info, pay_farmer, get_banks, send_verification } from "./actions.js";
import { can } from "@/modules/auth/utils.js";

export default {
  data() {
    return {
      info: { farmer_name: "", total_amount: 0, farmer_id: "" },
      currency: "UGX.",
      loading: false,
      error: false,
      payment_not_started: true,
      success: false,
      paynow: false,
      methods: [
        {value:"mobile", text:"Mobile"}, 
        {value:"bank", text:"Bank"}
      ],
      select_method: "mobile",
      banks: [],
      select_bank:'',
      verified:false,
      waited:1,
      pay_now:false,
      loadingbanks:false,
    };
  },
  computed:{
    url(){
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  methods: {
    can,
    get_payment_info,
    pay_farmer,
    get_banks,
    send_verification,
    save_farmer_amount
  },
  created() {
    bus.$on("pay-farmer", (payload) => {
      this.info={};
      this.info = payload;
      this.payment_not_started = true;
      this.error = false;
      this.success = false;
      this.loading = false;
      this.get_payment_info();
      this.pay_now = payload.pay_now?true:false;
      this.paynow=true
      this.verified=false;
      this.banks.length ==0 ? this.get_banks() : 0;
      this.waited=1;

      this.$bvModal.show("pay-farmer");

    });
  },
};
</script>

