<template>
    <div>
    <b-table
    v-if="items.name == 'advances'"
    :items="items.data"
    :fields="fields"
    sticky-header="50vh"
    head-variant="light"
    >
        <template #cell(name)="data">
           {{data.item.name}}
        </template>

        <template #cell(quantity)="data">
            {{data.item.quantity | localeString }}
        </template>

        <template #cell(price)="data">
            {{data.item.price | localeString }}
        </template>

        <template #cell(amount)="data">
            {{data.item.amount  | localeString }}
        </template>

        <template #cell(balance)="data">
            {{(data.item.amount - data.item.repay) | localeString }}
        </template>

        <template #cell(repay)="data">
            {{data.item.repay | localeString }}
        </template>

        <template #cell(time)="data">
            {{data.item.time | moment}}
        </template>

        <template #cell(action)="data" v-if="can('modify', 'inputs') === true">
            <b-dropdown no-caret class="m-md-2">
                <template #button-content>
                    <div><b-icon
                    icon="three-dots-vertical"
                    aria-hidden="true"
                    ></b-icon></div>
                </template>
                <b-dropdown-item @click="onEdit(data.item)">Edit</b-dropdown-item>
                <b-dropdown-item @click="onDelete(data.item)">Delete</b-dropdown-item>
                <b-dropdown-item @click="onPay(data.item)" v-show="data.item.payment_status !== 'paid' ">Add Payment</b-dropdown-item>
            </b-dropdown>
        </template>
    
    </b-table><b-spinner label="Loading..." v-if="pageIsLoading == true"></b-spinner>

    <b-table
    v-if="items.name == 'paid'"
    :items="items.data"
    :fields="paidFields"
    sticky-header="50vh"
    head-variant="light"
    >
        <template #cell(transaction_id)="data">
           {{data.item.transaction_id}}
        </template>

        <template #cell(name)="data">
           {{data.item.name}}
        </template>

        <template #cell(amount)="data">
            {{data.item.amount  | localeString }}
        </template>

        <template #cell(present_balance)="data">
            {{data.item.present_balance  | localeString }}
        </template>

        <template #cell(reference)="data">
            {{data.item.reference === ""?'_':data.item.reference}}
        </template>

        <template #cell(time)="data">
            {{data.item.time | moment}}
        </template>
    
    </b-table><b-spinner label="Loading..." v-if="pageIsLoading == true"></b-spinner>

    <b-modal :id="'edit-advance-item-'+items.name" title="Edit Input" hide-footer>
        <b-form @submit.prevent="updateAdvanceRecords">
        <b-container>
            <b-form-group
            label="Advance Type"
            label-for="advancetype"
            description=""
            >
            <b-form-input v-model="selectedAdvance.advance_type" type="text" readonly></b-form-input>
            </b-form-group>
            <b-form-group
            label="Item Name"
            label-for="item"
            description=""
            >
            <b-form-input v-model="selectedAdvance.name" type="text" readonly></b-form-input>
            </b-form-group>
            <b-form-group
            label="Units"
            label-for="Units"
            description=""
            >
            <b-form-input v-model="selectedAdvance.unit" type="text" readonly></b-form-input>
            </b-form-group>
            <b-row>
            <b-col> {{ currency }}&nbsp; {{ selectedAdvance.price }}&nbsp; per unit </b-col>
            </b-row>

            <b-form-group
            label="Quantity"
            label-for="quantity"
            description="Enter Quantity given as advance"
            >
            <b-form-input v-model="selectedAdvance.quantity" type="number" @keyup="calulateTotal" required></b-form-input>
            </b-form-group>
            <div class="row">
            <div class="col-sm-12 float-right">
                <h5 class="text-danger">Total Amount</h5>
                <h4>{{ selectedAdvance.amount }}</h4>
            </div>
            </div>
            <b-row>
                <div class="col-sm-12">
                    <b-button
                        variant="primary"
                        class="btn-sm float-right"
                                    type="submit"
                        >Update item</b-button
                    ><b-spinner label="Loading..." v-if="editAdvanceLoader == true"></b-spinner>
                </div>
            </b-row>
        </b-container>
        </b-form>
    </b-modal>
    <b-modal :id="'delete-advance-item-'+items.name" title="" size="sm" hide-footer>
        <h3 class="text-center">Delete Item</h3>
        <p class="text-center">Do you want to delete this item?<br>You will not be able to recover it</p>
        <div class="row">
            <div class="col-sm-12">
            <b-button
                class="btn-sm float-left"
                @click="cancelDelete"
                >cancel</b-button
            >
            <b-button
                variant="primary"
                class="btn-sm float-right"
                @click="handleDelete"
                >Yes, Delete</b-button
            ><b-spinner label="Loading..." v-if="deleteAdvanceLoader == true"></b-spinner>
            </div>
        </div>
    </b-modal>
    <b-modal :id="'pay-advance-item-'+items.name" title="Add Payment" hide-footer>
        <b-form @submit.prevent="makePayment">
        <b-container>
            <b-form-group
            label="Payment Methods"
            label-for="paymentMethod"
            description=""
            >
            <b-form-input v-model="form.paymentMethod" type="text" readonly></b-form-input>
            </b-form-group>
            <b-form-group
            label="Amount"
            label-for="amount"
            description=""
            >
            <b-form-input id="amount" v-model="form.amount" type="number" @keyup="makeBalanceCalculations" required></b-form-input>
            <b-alert v-if="isError" variant="danger" show>
                The Amount can't exceed current balance
            </b-alert>
            </b-form-group>
            <b-form-group
            label="Reference"
            label-for="reference"
            description=""
            >
            <b-form-input v-model="form.reference" type="text"></b-form-input>
            </b-form-group>

            <div class="row">
            <div class="col-sm-12 float-right">
                <h5 class="text-danger">Total Balance</h5>
                <h4>{{ currency }} {{ totalAdvanceBalance | localeString }}</h4>
            </div>
            </div>
            <b-row>
                <div class="col-sm-12">
                    <b-button
                        class="btn-sm float-left"
                        @click="cancelPay"
                        >cancel</b-button
                    >
                    <b-button
                        variant="primary"
                        class="btn-sm float-right"
                        :disabled="isError"
                        type="submit"
                        >Record Payment</b-button
                    ><b-spinner label="Loading..." v-if="payAdvanceLoader == true"></b-spinner>
                </div>
            </b-row>
        </b-container>
        </b-form>
    </b-modal>
    </div>
</template>
<style lang="css" scoped>

</style>
<script>

import moment from "moment";
import $ from 'jquery'
import axios from "axios";
import { can } from "@/modules/auth/utils.js";
export default {
    props: {
        items:Object,
        default:()=>{
            return {
                name:'',
                data:[]
            }
        }
    },
    data(){
        let fields = [
            {key: "name", label: "Item"},
            "unit",
            "quantity",
            { key: "price", label: "Unit Price" },
            "amount",
            {key: "balance", label: "Balance"},
            {key: "repay", label: "Paid"},
            { key: "time", label: "Date Taken" },
            {key:"action", label:""}
        ];
        let paidFields = [
            { key: "transaction_id", label: "Transaction ID" },
            { key: "amount", label: "Amount" },
            { key: "present_balance", label: "Balance" },
            {key: "reference", label: "Reference"},
            { key: "time", label: "Date paid" }
        ];
        return{
            fields,
            paidFields,
            selectedAdvance:{},
            currency:"UGX",
            paymentMethod:"Cash",
            editAdvanceLoader:false,
            deleteAdvanceLoader:false,
            payAdvanceLoader:false,
            pageIsLoading:false,
            singleAdvanceBalance: 0,
            totalAdvanceBalance:0,
            itemInterest:0,
            form:{
                paymentMethod:"Cash",
                amount:0,
                reference:""
            },
            isError: false,
        }
    },

    computed:{
        project_id(){
            return this.$store.state.project_id;
        }
    },

    methods:{
        can,
        // myRowClickHandler(record){
        //     // console.log("this is selected row data: ",record);
        //     // console.log("this is the index: ", index);
        //     this.selectedAdvance = record;
        //     this.$bvModal.show('edit-advance-item-'+this.items.name)
        // },
        onEdit(data){
            this.selectedAdvance = data;
            this.$bvModal.show('edit-advance-item-'+this.items.name);
            if(data.advance_type === "Cash"){
                this.getCurrentInterest();
            }
        },
        onDelete(data){
            this.selectedAdvance = data;
            this.$bvModal.show('delete-advance-item-'+this.items.name);
        },
        getCurrentInterest(){
            var self = this;
            axios.get(process.env.VUE_APP_BACKEND+"/advances/get-inputs-status",{
                headers: { 'Authorization': "Bearer " + localStorage.getItem("access_token"),
                        'Accept': 'application/json', 'Content-Type': 'application/json' },
                params: {project_id: this.project_id}
            })
            .then((response) => {
                // self.editAdvanceLoader = false;
                if(response.data.success === true && response.data.data.length > 0){
                    self.itemInterest = response.data.data[0].interest;
                }
                else{
                    // alert("No data collected");
                    self.$vToastify.error("No data collected");
                }
            });
        },
        onPay(data){
            var self = this;
            this.selectedAdvance = data;
            this.pageIsLoading = true;
            axios.get(process.env.VUE_APP_BACKEND+"/advances/get-payment-balance/"+data.id,{
                headers: { 'Authorization': "Bearer " + localStorage.getItem("access_token"),
                        'Accept': 'application/json', 'Content-Type': 'application/json' },
                params: {project_id: this.project_id}
            })
            .then((response) => {
                // self.editAdvanceLoader = false;
                if(response.data.success === true){
                self.singleAdvanceBalance = response.data.balance;
                self.totalAdvanceBalance = response.data.balance;
                self.pageIsLoading = false;
                self.$bvModal.show('pay-advance-item-'+this.items.name);
                }
                else{
                    self.$vToastify.error("No data collected");
                }
            });
        },
        cancelDelete(){
            this.$bvModal.hide('delete-advance-item-'+this.items.name);
        },
        cancelPay(){
            this.$bvModal.hide('pay-advance-item-'+this.items.name);
        },
        calulateTotal(){
            var costOfItem = 0;
            var newItemInterest = 0;
            if (this.selectedAdvance.advance_type === "Cash"){
                costOfItem = parseInt(this.selectedAdvance.quantity) * parseInt(this.selectedAdvance.price);
                newItemInterest = costOfItem * (this.itemInterest / 100);
                this.selectedAdvance.amount = costOfItem + newItemInterest;
            }
            else{
                this.selectedAdvance.amount = parseInt(this.selectedAdvance.quantity) * parseInt(this.selectedAdvance.price);
            }
        },
        makeBalanceCalculations(){
            if(this.form.amount > this.singleAdvanceBalance){
                // alert("You have input an amount greater than current balance");
                $("#amount").css('color', 'red');
                // this.$vToastify.error("You have input an amount greater than current balance");
                this.isError= true;
            }
            else{
                $("#amount").css('color', '#000');
                this.totalAdvanceBalance = this.singleAdvanceBalance - this.form.amount;
                this.isError= false;
            }
        },
        updateAdvanceRecords(){
            var self = this;
            this.editAdvanceLoader = true;
            var dataObject = {
                quantity:parseInt(this.selectedAdvance.quantity),
                amount: parseInt(this.selectedAdvance.amount),
                id: this.selectedAdvance.id,
            }
            axios.put(process.env.VUE_APP_BACKEND+"/advances/update-advance",JSON.stringify(dataObject),{
                headers: { 'Authorization': "Bearer " + localStorage.getItem("access_token"),
                        'Accept': 'application/json', 'Content-Type': 'application/json' },
                params: {partner_id: "AK/OT/0008"}
            })
            .then((response) => {
                self.editAdvanceLoader = false;
                if(response.data.success === true){
                // alert(response.data.message);
                self.$vToastify.success(response.data.message);
                
                self.$emit('SuccessfulUpdate');
                self.$bvModal.hide('edit-advance-item-'+this.items.name);
                }
                else{
                    self.$vToastify.error(response.data.message);
                }
            });
        },
        makePayment(){
            var self = this;
            this.payAdvanceLoader = true;
            var paymentObject = {
                payment_method: this.form.paymentMethod,
                advance_id: this.selectedAdvance.id,
                amount: parseInt(this.form.amount),
                project_id: this.project_id,
                reference: this.form.reference
            }
            axios.post(process.env.VUE_APP_BACKEND+"/advances/repay",JSON.stringify(paymentObject),{
                headers: { 'Authorization': "Bearer " + localStorage.getItem("access_token"),
                        'Accept': 'application/json', 'Content-Type': 'application/json' },
                params: {balance: parseInt(this.totalAdvanceBalance)}
            })
            .then((response) => {
                self.payAdvanceLoader = false;
                if(response.data.success === true){
                    // alert(response.data.message);
                    self.$vToastify.success(response.data.message);
                    self.$emit('SuccessfulUpdate');
                    self.form.amount = 0;
                    self.form.reference = 0;
                    self.totalAdvanceBalance = 0;
                    self.$bvModal.hide('pay-advance-item-'+self.items.name);
                }
                else{
                    self.$vToastify.error(response.data.message);
                }
            });
        },
        handleDelete(){
            var self = this;
            this.deleteAdvanceLoader = true;
            axios.delete(process.env.VUE_APP_BACKEND+"/advances/delete/"+this.selectedAdvance.id,{
                headers: { 'Authorization': "Bearer " + localStorage.getItem("access_token"),
                        'Accept': 'application/json', 'Content-Type': 'application/json' },
                params: {partner_id: "AK/OT/0008"}
            })
            .then((response) => {
                self.deleteAdvanceLoader = false;
                if(response.data.success === true){
                    // alert(response.data.message);
                    self.$vToastify.success(response.data.message);
                    
                    self.$emit('SuccessfulUpdate');
                    self.$bvModal.hide('delete-advance-item-'+this.items.name);
                }
                else{
                    self.$vToastify.error(response.data.message);
                }
            });
        }
    },

    filters: {
        moment: function (date) {
            return moment(date).format("lll");
        },
        localeString: function(data){
            return data.toLocaleString();
        }
    }
}
</script>
