const series = [
    {
        data: [],
    },
];
const chartOptions = {
    chart: {
        type: "bar",
        height:200,
    },
    noData:{
        text:"Farmer has no Harvests in time frame"
    },
    plotOptions: {
        bar: {
            borderRadius: 4,
        },
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        categories: [],
    },
};
const FARMER_BASE = process.env.VUE_APP_BACKEND + process.env.VUE_APP_FARMER_PFX;
export {series,chartOptions,FARMER_BASE}