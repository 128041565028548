<template>
  <b-modal
    :id="modal_id"
    ref="addInput"
    size="xl"
    title="Record New Sale"
    hide-footer
  >
    <add-input-form
      v-show="!loadingInputSubmission"
      @Submit="giveInput"
      :title="title"
      :userData="userData"
      :module="module"
    ></add-input-form>

    <b-container v-if="loadingInputSubmission">
      <b-row class="d-flex justify-content-center p-3">
        <b-spinner
          style="width: 3rem height: 3rem"
          label="Large Spinner"
        ></b-spinner>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import AddInputForm from "@/components/add_input_form/AddInputForm.vue";
import { giveInput } from "./actions.js";

export default {
  props: {
    modal_id: {
      type: String,
      default: ()=>{
        return ""
      }
    },
    userData: {
      type:Object,
      default: ()=>{
        return {}
      }
    }
  },
  data() {
    return {
      loadingInputSubmission: false,
      title:"Item(s) Sold",
      module:"Input",
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/"
    },
    url() {
      return (
        this.baseUrl +
        this.$store.state.project_id +
        "/input-sales/"
      )
    },
  },
  components: {
    AddInputForm,
  },
  methods: {
    giveInput,
  },
};
</script>

<style>
.height-20 {
  max-height: 20vh;
}
/* .border-right {
  border-left: gray 1px solid;
} */
.float-right {
  float: right;
  text-align: right;
}
</style>
