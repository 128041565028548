<template>
  <b-container id="dashboard">

    <div class="page-head">
      <h5 class="sub-page-title">Payment Details</h5>
    </div>
    <div v-if="loading">
          <b-row class="container pt-3 pb-3">
            <span class="font-weight-light text-muted">Loading...&nbsp;&nbsp;</span>
            <b-spinner variant="secondary" label="Loading..."></b-spinner>
          </b-row>
        </div>
    <div v-show="!loading">
      <table class="table">
        <!-- <tr v-for="(option, i) in payment_options" :key="i"> -->
        <tr  v-show="select_method == 'mobile'">
          <td width="1px">
            <span class="num-circle">1</span>
          </td>
          <td>
            <p class="name">Mobile Details</p>
            <p class="ac-name mb-0">
              {{ info.contact }}, {{info.account_name | capitalize }}
            </p>
          </td>
          <td>
            <!-- <span v-if="option.default" class="badge badge-primary"
              ><i class="uil uil-check"></i> Default</span
            > -->
          </td>
          <!-- <td>
            <button class="btn-icon uil uil-pen"></button>
            <button class="btn-icon uil uil-times"></button>
          </td> -->
        </tr>
        <tr v-show="select_method == 'bank'">
          <td width="1px">
            <!-- <span class="num-circle">{{ i + 1 }}</span> -->
          </td>
          <td>
            <p class="name">Bank Details</p>
            <p class="ac-name mb-0">
              {{ info.account_number }}, {{ info.account_name  | capitalize  }}
            </p>
          </td>
          <td>
            <!-- <span v-if="option.default" class="badge badge-primary"
              ><i class="uil uil-check"></i> Default</span
            > -->
          </td>
          <!-- <td>
            <button class="btn-icon uil uil-pen"></button>
            <button class="btn-icon uil uil-times"></button>
          </td> -->
        </tr>
      </table>

      <button class="btn btn-sm btn-primary" @click="edit_payment_info"  v-analytics:click="['edit_farmer_payment',{element:'Suppliers'}]">
        <i class="uil uil-pen"></i> Edit Payment
      </button>
    </div>
    <payment-edit @EditPaymentInfoSuccess="get_payment_info"></payment-edit>
  </b-container>
</template>
<script>
import { get_payment_info } from "@/components/payment_info/actions.js";
import { edit_payment_info } from './actions.js';
import PaymentEdit from "@/components/payment_info/PaymentInfo.vue";
export default {
  components:{PaymentEdit},
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      loading:false,
      select_method:'',
    };
  },
  filters:{
    capitalize: (word)=> {
      return word?word
        .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
        .trim():'-';
    },
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
    url() {
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  methods: {
    get_payment_info,
    edit_payment_info,
  },
  mounted() {
    this.get_payment_info();
  },
};
</script>
<style scoped lang="scss">
td {
  vertical-align: middle;
}
.name {
  margin-bottom: 0px;
  font-weight: bold;
}

.num-circle {
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  border-radius: 40px;
  border: 1px solid #aaa;
  display: block;
}
</style>
