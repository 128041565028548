<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="edit-contacts"
      centered
      title="Edit Contacts"
      ok-title="Save Changes"
      @ok="saveChanges"
    >
      <label class="control-label">PhoneNumber</label>
      <input class="form-control" placeholder="" type="tel" />

      <label class="control-label">Email</label>
      <input class="form-control" placeholder="" type="email" />

      <label class="control-label">District</label>
      <input class="form-control" placeholder="" />
      <label class="control-label">Sub-county</label>
      <input class="form-control" placeholder="" />
      <label class="control-label">Village</label>
      <input class="form-control" placeholder="" />
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    saveChanges() {
      alert("Saved Successfully!");
    },
  },
};
</script>
