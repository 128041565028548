<template>
  <b-container>
   

    <div class="filter">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-auto">
              <div class="form-select-2 form-input">
                <label for="select-crop">Select Crop</label>
                <select name="" id="select-crop" :v-model="crop_selected">
                  <option v-for="(c, i) in crops" :key="i" :value="c.name">{{
                    c.name
                  }}</option>
                </select>
              </div>
            </div>
            <div class="col-auto ps-0">
              <div class="form-select-2 form-input">
                <label for="select-date">Select Date</label>
                <select name="" id="select-date" :v-model="date_selected">
                  <option v-for="(d, i) in dates" :key="i" :value="d.date">{{
                    d.clean_date
                  }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <button class="btn btn-sm btn-primary">
            <i class=" uil uil-plus"></i> Upload Monitoring Data
          </button>
        </div>
      </div>
    </div>
    <div class="sm-stats">
<div class="row my-2">
    <div class="col-12 col-md-auto ">
        <div class="stat-item">
            <div class="label">Garden Size</div>
            <div class="value">30 Acres</div>
        </div>
    </div> <div class="col col-md-auto">
        <div class="stat-item">
            <div class="label">Planting Date</div>
            <div class="value">20 Feb 2021</div>
        </div>
    </div> <div class="col col-md-auto">
        <div class="stat-item">
            <div class="label">Next Planting Date</div>
            <div class="value">20 Jul 2021</div>
        </div>
    </div> <div class="col col-md-auto">
        <div class="stat-item">
            <div class="label">Expected Yield</div>
            <div class="value">300 Tons</div>
        </div>
    </div> <div class="col col-md-auto">
        <div class="stat-item">
            <div class="label">Last Chemical use</div>
            <div class="value">Acetone, 3 years ago</div>
        </div>
    </div>
</div>
    </div>

    <div class="body">
 <div class="row">

     <div class="col-8">
                <div>
            <h4 class="subtitle">
                INSPECTION RESULTS (JUN 24 2021)
            </h4>
        </div>
        <table class="table table-striped">
            <tbody>
                <tr v-for="(row, i) in monitoring_data.results" :key="i">
                <td>{{row.label}}</td>
                <td>{{row.value}}</td>

                </tr>
            </tbody>
        </table>
     </div>
     <div class="col-4"></div>
 </div>

    </div>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      crop_selected: "Chia",
      date_selected: "01/30/2022",
      crops: [
        { name: "Chia" },
        { name: "Beans" },
        { name: "SoyBeans" },
        { name: "Coffee" },
      ],
      dates: [
        { date: "01/30/2022", clean_date: "30 Jan 2022" },
        { date: "02/30/2022", clean_date: "30 Feb 2022" },
        { date: "03/30/2022", clean_date: "30 Mar 2022" },
      ],
      monitoring_data:{
          
           date:"01/01/2021",
           inspectors:[{name:"Alex Mabeeri"},{name:"Alice Nanyazi"}],
           results:[
               {label:"Weeding", value:"Yes"},
               {label:"Applied Fertilizer", value:"NPK 10:8:1"},
               {label:"Pests", value:"Rats"},
               {label:"Floods", value:"No"},
               {label:"Expected Yield", value:"300 Tons"},
               {label:"Remarks", value:"Look like everything is okay"},
               ]
          
      }
    };
  },
}
</script>

<style lang="scss" scoped>
 .sm-stats {
     padding: 24px 0;
     .col-md-auto{
         border-right: 1px solid #C4C4C4 ;
         &:last-child{border:none}
     }
  .stat-item{

.label{
          font-size:13px;
          color: #838383;
          margin-bottom:4px;
      }
      .value{
         font-size:14px;
          color: #333;
      }
  }
 }

</style>