<template>
  <div class="m-0">
     
    
    <b-row class="m-0">
      <div class="col-4 p-0">
       <div class="p-2 px-3">
          <b-select text="All produce" class="form-select mb-2 w-100"
            v-model="selectedCrop" @change="filterByCrop" variant="light">
            <b-select-option selected class="form-select"
              value="All">All</b-select-option>
            <b-select-option
              v-for="(crop,idx) in produce"
              :key="idx" :value="crop"
              >{{ crop }}</b-select-option
            >
          </b-select>
     
          <b-select
            v-model="selectedPeriod"
            @change="filterByPeriod"
            text="All Seasons" class="form-select mb-2 w-100"
            variant="light"
          >
            <b-select-option
              v-for="(season, idx) in periods"
              :key="'d' + idx" :value="season"
              >{{ season }}</b-select-option
            >
          </b-select>
          <FunctionalCalendar
            v-if="showDatePicker"
            v-model="calendarData"
            :configs="calendarConfigs"
            @choseDay="choseDay"
            @closed="dayClicked"
            ref="Calendar"
          ></FunctionalCalendar>
       </div>
        
        <div class="items-list">
          <div
            class=" item"
            v-for="(item, idx) in farmerProduce"
            :key="idx"
            @click="produceDetails = item"
            :class="{ active: produceDetails == item }"
            id="item_crop"
          >
            <b-row @click="rowSelected(item)">
              <b-col class="crop">
                {{ item.cropItem }}
              </b-col>
              <b-col cols="auto" class=" date">
                {{ item.period }} day(s)
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <div class="col-8 border-left">
        <b-row>
          <b-col class="float-right">
        <b-button 
          v-if="can('modify', 'produce') && suppliers_loaded"
          class="float-right" 
          v-analytics:click="['farmer_record_produce',{element:'Suppliers'}]"
          @click="showModal"
           variant="primary">
          <b-icon-plus></b-icon-plus> Record Produce
        </b-button>
        <p v-if="!suppliers_loaded" class="float-right">
          <small class="text-muted">Loading Supplier Data...</small>
        </p>
        </b-col>
        </b-row>
        <b-container v-show="loading">
        
      <b-row class="d-flex justify-content-center mb-3">
        <b-spinner
          style="width: 3rem; height: 3rem;"
          label="Large Spinner"
        ></b-spinner>
      </b-row>
    </b-container>
        <div class="container-fluid-" v-show="farmerProduce.length===0 && !loading">
          <h6 class="text-muted">
            No Recorded produce...
          </h6>
        </div>
        <div class="container-fluid-" v-show="farmerProduce.length>0">
          
          <b-row>
            <b-col>
              <b-row class="px-3 "
            ><h5>{{ produceDetails.crop }}</h5></b-row
          >
          <div class="crop-details">
          <b-row class="">
            <b-col class="col-md-4 col-6">
              GRN No.
            </b-col>
            <b-col>
              {{ produceDetails.receipt_number }}
            </b-col>
          </b-row>
          <b-row class="">
            <b-col class="col-md-4 col-6">
              Variety
            </b-col>
            <b-col>
              {{ produceDetails.variety }}
            </b-col>
          </b-row>
          <b-row class=" ">
            <b-col class="col-md-4 col-6">
              Date
            </b-col>
            <b-col>
              {{ produceDetails.date }}
            </b-col>
          </b-row>
          <b-row class=" ">
            <b-col class="col-md-4 col-6">
              Quantity
            </b-col>
            <b-col>
              {{ produceDetails.quantity }}
            </b-col>
          </b-row>
          <b-row class=" ">
            <b-col class="col-md-4 col-6">
              Moisture Content
            </b-col>
            <b-col>
              {{ produceDetails.moisture }}
            </b-col>
          </b-row>
          <b-row class=" ">
            <b-col class="col-md-4 col-6">
              Foreign Matter
            </b-col>
            <b-col>
              {{ produceDetails.foreign }}
            </b-col>
          </b-row>
          <b-row class=" ">
            <b-col class="col-md-4 col-6">
              Net Weight
            </b-col>
            <b-col>
              {{ produceDetails.weight }}
            </b-col>
          </b-row>
          <b-row class=" ">
            <b-col class="col-md-4 col-6">
              Gross Weight
            </b-col>
            <b-col>
              {{ produceDetails.gross_weight }}
            </b-col>
          </b-row>
          <b-row class=" ">
            <b-col class="col-md-4 col-6">
              Price Per Unit
            </b-col>
            <b-col>
              {{ produceDetails.unit_price }}
            </b-col>
          </b-row>
          <b-row class="border-top my-3 ">
            <b-col class="col-md-4 col-6">
              Total Amount
            </b-col>
            <b-col class="total text-accent">
              {{ produceDetails.amount }}
            </b-col>
          </b-row>
          <b-row class="" v-show="false">
            <b-col class="col-md-4 col-6">
              Payment Status
            </b-col>
            <b-col v-show="false">
              {{ produceDetails.payment_status }}
              <b-button @click="go_to_payments" id="payNow" size="sm" variant="success">
                <b-icon-check></b-icon-check> Pay now
              </b-button>
            </b-col>
          </b-row>
          </div>
            </b-col>
            <b-col v-if="produceDetails.payment_mode_consent || produceDetails.produce_receipt">
              <div>
                <hr/>
                <h6 v-if="produceDetails.payment_mode_consent" >Payment Consent</h6>

                <b-img 
                  v-if="produceDetails.payment_mode_consent" 
                  :src="produceDetails.payment_mode_consent" 
                  fluid alt="Responsive image"
                >
                </b-img>
                <hr/>
                <h6 v-if="produceDetails.produce_receipt" >Produce Receipt</h6>
                <b-img 
                  v-if="produceDetails.produce_receipt" 
                  :src="produceDetails.produce_receipt" 
                  fluid alt="Responsive image"
                ></b-img>

              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="float-right">

            <b-button 
              v-b-modal.delete-produce
              v-if="produceDetails.status!=='DELETED' && can('modify','produce')"
              variant="outline-danger"
            >
              <b-icon-trash></b-icon-trash>&nbsp;Delete

            </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="float-right">

            &nbsp;
            </b-col>
          </b-row>
          
        </div>
      </div>
    </b-row>
    <add-produce :modal_id="sub_modal_id" @SupplierDataLoaded="supplier_data_loaded" :selectedCrop="crop" :farmerSelected="farmerCrop">
    </add-produce>
    <delete-produce @SuccessfulDelete="reload" :selectedProduce="produceDetails">
      
    </delete-produce>
  </div>
</template>
<script>
import AddProduce from "@/components/add_produce/AddProduce.vue";
import DeleteProduce from "./subcomponents/DeleteProduce.vue";
import { NameToCamelCase } from '../../../add_produce/actions.js';
import axios from "axios";
import moment from "moment";
import $ from 'jquery';
import {bus} from "@/main.js";
import { can } from "@/modules/auth/utils.js";
import { FunctionalCalendar } from 'vue-functional-calendar';

export default {
  components: { 
    AddProduce,FunctionalCalendar,
    DeleteProduce
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    let items = [
        {crop:"Soy Beans (569 Kg)",period:"2 days"},
        {crop:"Maize (650 Kg)",period:"5 days"}
      ];
    return {
      periods: [
        "All Seasons",
        "This Season",
        "Last Season",
        "Single Date",
        "Date Range"
      ],
      crops: ["maize", "soya"],
      selected: this.item.data,
      current_season:'',
      fId: '',
      produceId: '',
      season: [],
      baseURI: process.env.VUE_APP_BACKEND,
      token: localStorage.getItem("access_token"),
      produceDetails:{},
      items,
      produce: [],
      selectedCrop: "All",
      selectedPeriod: "All Seasons",
      loading: false,
      farmerProduce: [],
      showPayBtn: true,
      sub_modal_id:"new-produce",
      produceCompParent: false,
      crop: "",
      showDatePicker: false,
      calendarData: {},
      calendarConfigs: {
        sundayStart: false,
        dateFormat: 'yyyy/mm/dd',
        isDatePicker: false,
        isDateRange: false,
        isAutoCloseable: false,
        isModal: false,
        isTypeable: false,
        isSeparately: false,
        isLayoutExpandable: false,
        isDark: true
      },
      farmer: "",
      farmerCrop: {
        crop: "",
        farmer: ""
      },
      suppliers_loaded:false,
    };
  },
  methods: {
    showModal(){
      bus.$emit("set-crop", {
        crop: this.crop,
        farmer: this.farmer
      });
      this.$bvModal.show(this.sub_modal_id);
    },
    reload(){
      this.fetchRecords(this);
    },
    rowSelected(item){
      this.setCrop(item.crop,item.name);
    },
    go_to_payments(){
      this.$emit("GotoPayments");
    },
    choseDay(){
      if(this.calendarData.selectedDate){
        this.season = [];
        const this_day = this.calendarData.selectedDate;
        this.season.push(this.formatDate(this_day,'filter'));
        this.calendarData = {};
        this.fetchRecords(this);
      }
    },
    supplier_data_loaded(){
      this.suppliers_loaded=true;
    },
    dayClicked(){
      let dateRange = this.calendarData.dateRange;
      if(dateRange && 'end' in dateRange){
        this.season = [];
        const startDate = this.formatDate(dateRange.start,'filter');
        const endDate = this.formatDate(dateRange.end,'filter');
        this.season.push(startDate,endDate);
        this.calendarData = {};
        this.fetchRecords(this);
      }
    },
    filterByPeriod(){
      if(this.selectedPeriod === "Single Date"){
        this.showDatePicker = true;
        this.calendarConfigs.isDatePicker = true;
        this.calendarConfigs.isDateRange = false;
        this.calendarConfigs.isSeparately = false;
        this.calendarConfigs.isModal = true;
        this.calendarConfigs.isAutoCloseable = true;
      } else if(this.selectedPeriod === "Date Range"){
        this.showDatePicker = true;
        this.calendarConfigs.isDateRange = true;
        this.calendarConfigs.isSeparately = true;
        this.calendarConfigs.isModal = true;
        this.calendarConfigs.isAutoCloseable = true;
      } else {
        this.showDatePicker = false;
        this.season = this.determineDateRange(this.selectedPeriod);
        this.fetchRecords(this);
      }
    },
    determineDateRange(period){
      var season_a_mons = ['01','02','03','04','05','06','07'];
      var season_b_mons = ['08','09','10','11','12'];
      var season_a_mon_range = ['01-01','07-31'];
      var season_b_mon_range = ['08-01','12-31'];
      var current_date = new Date().date;
      var current_year = moment(current_date).format('Y'),
          current_month = moment(current_date).format('M');
      if(parseInt(current_month) < 10) current_month = '0'+current_month;
      var period_range = [];
      var past_year = parseInt(current_year) - 1;

      switch(period){
        case "This Season":
          if (season_a_mons.includes(current_month)){
            season_a_mon_range.map(item => {
              period_range.push(current_year+'-'+item);
            })
          } else if (season_b_mons.includes(current_month)){
              season_b_mon_range.map(item => {
                period_range.push(current_year+'-'+item);
              })
          }
          break;
        case "Last Season":
          if(parseInt(current_month) <= 7){
            season_b_mon_range.map(item => {
              period_range.push(past_year+'-'+item);
            });
          } else {
            season_a_mon_range.map(item => {
              period_range.push(current_year+'-'+item);
            })
          }
          break;
        case "Last 2 Seasons":
          if(parseInt(current_month) <= 7){
            period_range[0] = past_year+'-'+season_a_mon_range[0]; 
            period_range[1] = past_year+'-'+season_b_mon_range[1];
          } else {
            period_range[0] = past_year+'-'+season_b_mon_range[0]; 
            period_range[1] = current_year+'-'+season_a_mon_range[1];
          }
          break;
      }
      return period_range;
    },
    formatCropItem(crop,qty,units){
      return NameToCamelCase(crop)+' (' + qty.toLocaleString()+' '+NameToCamelCase(units) +' )';
    },
    can,
    setCrop(crop,farmer){
      this.crop = crop;
      this.farmer = farmer;
      this.farmerCrop.crop = crop;
      this.farmerCrop.farmer = farmer;
    },
    filterByCrop(){
      if(this.selectedCrop !== 'All'){
        this.farmerProduce = this.filteredItems.filter(
        item => item['crop'].toLowerCase().includes(this.selectedCrop.toLowerCase()));
      } else this.farmerProduce = this.filteredItems;
    },
    formatDate(date,context){
      if(context === 'showDetails'){
        return moment(date).format('DD/MM/YYYY');
      } else if (context === 'filter'){
        return moment(date).format('YYYY-MM-DD');
      }
    },
    getProduceDetails(produceId,produceCompParent){
      if(produceCompParent){
        this.farmerProduce.map(item => {
          if(item['id'] === produceId){
            this.produceDetails = item;
            this.checkPaymentStatus(this.produceDetails['payment_status']);
            this.setCrop(this.produceDetails.crop,
                        this.produceDetails.name);
            this.crop = this.produceDetails.crop;
          }
        });
      } else {
        this.produceCompParent = true;
        this.produceDetails = this.farmerProduce[0];
        this.checkPaymentStatus(
              this.produceDetails['payment_status']);
        this.setCrop(this.produceDetails.crop,
                    this.produceDetails.name);
        this.crop = this.produceDetails.crop;
      }
      this.crop = this.produceDetails.crop;
    },
    checkPaymentStatus(status){
      if(status === "Unpaid"){
        $("#payNow").css('display','block');
        // this.showPayBtn = true;
      } else {
        $("#payNow").css('display','none');
        // this.showPayBtn = false;
      }
    },
    fetchRecords(dataObj){
      dataObj.loading = true;
      var self = this;
      dataObj.parameters = {
        season: JSON.stringify(dataObj.season),
        project_id: dataObj.projectId,
        farmer: dataObj.fId
      }

      axios
        .get(this.url + '/supplied-by-farmer', {
          headers:{'Authorization': 'Bearer ' + this.token},
          params: dataObj.parameters
        })
        .then((result) => {
          if(result.data.success){
            dataObj.items = [];
            dataObj.filteredItems = [];
            dataObj.produce = [];
            dataObj.farmerProduce = [];
            dataObj.produceDetails = {};
            let response = result.data;
            if(response.data.length !== 0){
              response.data.map(item => {
                let obj = {
                  'date': this.formatDate(item['date_received'],'showDetails'),
                  'quantity': item['quantity_supplied'].toLocaleString()+' '+NameToCamelCase(item['units']),
                  'moisture': parseFloat(item['moisture_content']).toFixed(2).toString()+'%',
                  'foreign': parseFloat(item['foreign_matter']).toFixed(2).toString()+'%',
                  'weight': parseFloat(item['quantity_supplied'].toFixed(2)).toLocaleString()+' '+NameToCamelCase(item['units']),
                  'gross_weight': item['gross_weight'] ? parseFloat(item['gross_weight'].toFixed(2)).toLocaleString()+' '+NameToCamelCase(item['units']): 0.00,
                  'unit_price': 'UGX '+item['unit_price'].toLocaleString(),
                  'amount': 'UGX '+item['amount_used'].toLocaleString(),
                  'payment_status': (item['payment_status']==="pending payment"? "Unpaid": "Paid"),
                  'receipt_number':item.receipt_number,
                  'farmer_name':item.farmer_name,
                  'crop': NameToCamelCase(item.value_chain),
                  'id': item['id'],
                  'name': NameToCamelCase(item['farmer_name']),
                  'period': this.dateDifference(item['date_received']),
                  'variety':item.variety,
                  'status':item.status,
                  'cropItem': this.formatCropItem(NameToCamelCase(item.value_chain),
                                              item['quantity_supplied'],item['units']),
                  'produce_receipt':item.produce_receipt,
                  'payment_mode_consent':item.payment_mode_consent
                }
                dataObj.farmerProduce.push(obj);
                this.getProduceDetails(dataObj.produceId,dataObj.produceCompParent);
                dataObj.filteredItems.push(obj);
              })
              dataObj.produce = response.crops;
            }
          }
        })
        .catch(error => {
          console.log(error);
          // alert("Failed,Confirm you've got internet connection and Try Again...");
          self.$vToastify.error("Failed,Confirm you've got internet connection and Try Again...");
        })
        .finally(() => dataObj.loading = false)
    },
    dateDifference(date){
      const a = moment();
      const b = moment(date);
      return a.diff(b, 'days');
    }
  },
  mounted() {
    this.fId = this.item.farmer_id;
    this.farmer = this.item.name;
    this.fetchRecords(this);
    if('parentComp' in this.selected){
      this.produceCompParent = true;
      this.produceId = this.selected.id;
      this.setCrop(this.selected.produce,this.item.name);
    }
    bus.$on("farmer-bulk",(farmer_id)=>{
      if(farmer_id==this.fId){
        this.fetchRecords(this);
      }
    });
  },
  computed: {
    projectId(){
      return this.$store.state.project_id;
    },
    url(){
      return `${this.baseURI}/api/v3${this.$store.state.url_prefix}/produce`
    }
  }
};
</script>
<style scoped lang="scss">
.crop-details{
   .row{
  .col{
    padding-top: 8px;
    &:last-child{
font-weight: 400 !important;
color: #76787a;
    }
  }
   }
}
.hide {
  display: none;
}
.items-list {
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;

  

  .item{
      padding: 12px 16px;
    border-bottom: 1px solid #e4e5e6;
    border-right: 4px solid transparent;
    &.active {
  border-right-color: #3583c7;
  color: #3583c7;
  background-color: rgba(101, 110, 128, 0.1);
}
  }
}

div.date{
font-size: 13px;
color: #989a9c;
}
div.crop{
font-size: 14px;
color: #38393b;
}

.total{
  font-weight: bold !important;
  font-size: 24px;
}
</style>
