<template>
    <b-container>
        Reports
    </b-container>
</template>
<script>

export default ({
    data() {
        return {}
    },
})
</script>
