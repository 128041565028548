import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";
function get_user_input_sales() {
  this.loading = true;
  axiosApiInstance({
    url: this.url,
    method: "get",
    params: {
      user_id: this.item.farmer_id,
    },
  })
    .then((res) => {
      this.items = res.data.data;
      this.loading = false;
    })
    .catch((error) => {
      this.loading = false;

      this.$vToastify.error("Failed to load farmer input sales");
      this.$vToastify.error(error.response.data.message);
    });
}
function format_date(date) {
  return date ?  moment(date).format("llll")  : "-";
}
export { get_user_input_sales, format_date };
