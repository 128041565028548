<template>
  <b-container>
    <div class="page-head">
    <b-row>
     
      <b-col v-show="ctab=='advances'">
        <b-button :disabled="!can('modify', 'inputs')" class="" v-b-modal.new-advance
        v-analytics:click="['add_advance',{element:'Suppliers'}]"
        variant="primary">
          <b-icon-plus></b-icon-plus> New Record
        </b-button></b-col
      >
      <b-col v-show="ctab=='input_sales'">
        <b-button :disabled="!can('modify', 'inputs')" class="" v-b-modal.record-sale
        v-analytics:click="['add_advance',{element:'Suppliers'}]"
        variant="primary">
          <b-icon-plus></b-icon-plus> New Record
        </b-button>
      </b-col>
    </b-row>
    </div>

    <div class="row">
      <div class="container">
        <div class="nav flex-row nav-pills" role="tablist">
          <a
            class="nav-link show"
            role="tab"
            :class="{ active: ctab == 'advances' }"
            v-analytics:click="['select_advances',{element:'Suppliers'}]"
            @click="ctab = 'advances'"
            >Advances</a
          >
          <a
            class="nav-link"
            role="tab"
            :class="{ active: ctab == 'paid' }"
            @click="ctab = 'paid'"
            v-analytics:click="['select_advance_history',{element:'Suppliers'}]"
            >Payment History</a
          >
          <a
            class="nav-link"
            role="tab"
            :class="{ active: ctab == 'input_sales' }"
            @click="ctab = 'input_sales'"
            v-analytics:click="['select_advance_sales',{element:'Suppliers'}]"
            >Inputs Bought</a
          >
        </div>
      </div>
      <br />
      <br />
      <div class="container">
        <div class="tab-content">
          <div
            class="tab-pane fade"
            role="tabpanel"
            :class="{ 'show active': ctab == 'advances' }"
          >
            <div class="text-center" v-if="loadingAdvanceData">
              <b-spinner variant="primary" label="Text Centered"></b-spinner>
            </div>
            <div v-if="!loadingAdvanceData"><advances-table :items="advances" @SuccessfulUpdate="onPageRefresh"></advances-table></div>
          </div>
          <div
            class="tab-pane fade"
            role="tabpanel"
            :class="{ 'show active': ctab == 'paid' }"
          >
            <advances-table :items="paid"></advances-table>
            <add-advance :modal_id="ref_id" :userData="item" @AdditionSuccess="onPageRefresh"></add-advance>
            <add-input-sale :modal_id="input_sale_modal" :userData="item" @AdditionSuccess="onPageRefresh"></add-input-sale>
          </div>
          <input-sales
            class="tab-pane fade"
            role="tabpanel"
            :item="item"
            :class="{ 'show active': ctab == 'input_sales' }"
          >
          </input-sales>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import AddAdvance from '@/components/add_advance/AddAdvance.vue';
import AdvancesTable from "./subcomponents/Table.vue";
import InputSales from "./subcomponents/InputSales/InputSales.vue";
import axios from "axios";
import { can } from "@/modules/auth/utils.js";
import { checkTab } from "./actions";
import AddInputSale from '@/components/add_input_sale/AddInputSale.vue';

export default {
  components: { AdvancesTable, AddAdvance, InputSales, AddInputSale },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      advances: {
        name:'advances',
        data:[]
      },
      paid: {
        name:'paid',
        data:[]
      },
      ctab: "advances",
      loadingAdvanceData:false,
      ref_id:"new-advance",
      input_sale_modal:"record-sale",
    };
  },
  computed:{
    project_id(){
      return this.$store.state.project_id;
    }
  },
  mounted: function(){
    this.getUserAdvanceData(this);
    this.getUserPaidAdvance(this);
    this.checkTab();
  },
  methods: {
    can,
    checkTab,
    getUserAdvanceData(dataObj){
      this.loadingAdvanceData = true
      axios.get(process.env.VUE_APP_BACKEND+"/advances/user-advance", {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
        params: {user_id: dataObj.item.farmer_id, project_id: this.project_id}
      })
      .then(function (response) {
        dataObj.advances.data = response.data.data;
        dataObj.loadingAdvanceData = false;
      })
    },
    getUserPaidAdvance(dataObj){
      this.loadingAdvanceData = true
      axios.get(process.env.VUE_APP_BACKEND+"/advances/paid-advance", {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
        params: {user_id: dataObj.item.farmer_id, project_id: this.project_id}
      })
      .then(function (response) {
        dataObj.paid.data = response.data.data;
        dataObj.loadingAdvanceData = false;
      })
    },
    onPageRefresh(){
      this.getUserAdvanceData(this);
      this.getUserPaidAdvance(this);
      this.$emit('SuccessfulChange');
    },
  },

};
</script>
<style lang="css" scoped></style>
