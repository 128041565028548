<template>
  <b-container id="dashboard">
    <div class="page-head">
      <h5 class="sub-page-title">Contracts</h5>
    </div>
    <div class="row">
    <div class="col-8">
      <div key="contract" v-if="contract.number">
        <table class="table-summary">
          
          <tbody>
            <tr>
              <td>Number</td>
              <td>{{contract.number}}</td>
            </tr>
          
            <tr>
              <td>Contract Date</td>
              <td>01/01/2021</td>
            </tr>

            <tr>
              <td>Crop</td>
              <td>Garden Egg</td>
            </tr>
            <tr>
              <td>Quantity</td>
              <td>450,000 Kg</td>
            </tr>
            <tr>
              <td>Price per kg</td>
              <td>5000</td>
            </tr>
            <tr>
              <td>Delivery Period</td>
              <td>30/12/2022</td>
            </tr>
            <tr>
              <td>Attachment</td>
              <td>FileName.pdf</td>
            </tr>
          </tbody>
          <tfoot>
            <th colspan="2">
              <!-- <h4 class="title">Bio info</h4> -->
            
              <b-button
                class="btn btn-sm btn-outline-primary me-2"
                v-b-modal="'edit-contract'"
                ><i class="uil uil-trash"></i>Delete</b-button
              >
               <b-button
                class="btn btn-sm btn-outline-primary me-2"
                ><i class="uil uil-zip"></i> Archive</b-button
              >
               <b-button
                class="btn btn-sm btn-outline-primary "
                v-b-modal="'edit-contract'"
                ><i class="uil uil-pen"></i> Edit</b-button
              >
            </th>
          </tfoot>
        </table>
      </div>
      <div class="empty center py-5" v-if="!contract.number">
        <i class="uil uil-sad icon"></i>
        <div class="title">No contract</div>
        <div class="msg">
          There is current no contract arragment with {{ farmer_name }}.
        </div>
        <button class="btn btn-primary" v-b-modal="'edit-contract'">
          Create Contract
        </button>
      </div>
      </div>
    <div class="col-4">
      <table class="w-100">
        <td width="1px">
          <apexchart
              width="120"
              height="120"
              type="donut"
              :options="options"
              :series="series"
            ></apexchart>
        </td>
        <td>
          <h5 class="subtitle mt-0">Garden Egg Brought</h5>
          <h4>{{series[0]}}/{{total}} <small>Tons</small></h4>
        </td>
      </table>
      </div>
    </div>
    <edit-contract></edit-contract>
  </b-container>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import EditContract from "../modals/EditContract";
export default {
  components: { EditContract, apexchart: VueApexCharts  },
  props: {
    item: {
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data() {
    return {
      contract:{
        number:34677,},
        total:450,
        series: [100, 350],
      options: {
          legend: {
    show: false
  },
        dataLabels: {
          enabled: false,
        },
        labels: ["Brought", "Pending"],
        colors: ["#3F7BB6", "#C4C4C4"],
        plotOptions: {
          pie: {
            donut: {
              size:"85%",
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Brought',
                  formatter: function (w) {
                    // console.log(w)
                    return w.globals.seriesTotals.reduce((i,total) => {
                     
                     
                      return total
                    }, 0)
                  }
                },
              },
            },
          },
        },
      },
      }
    },
  
  mounted() {
    
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
td{
  vertical-align: middle;
}

</style>