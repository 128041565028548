import { axiosApiInstance } from "@/plugins/axios";
function delete_produce() {
  this.isloading = true;
  axiosApiInstance({
    url: this.url + "/" + this.selectedProduce.id,
    method: "delete",
    params: {
        project_id:this.project_id
    }
  })
    .then((res) => {
      console.log(res.status);
      this.isloading = false;
      this.$emit("SuccessfulDelete");
      setTimeout(() => {
      this.$bvModal.hide('delete-produce');
      },2000);
    })
    .catch((error) => {
      this.isloading = false;
      console.log(error);
    });
}

export { delete_produce };
