<template>
  <div v-if="Number(balance)>0">
    <h5 class="subtitle">
      Pending Payment
    </h5>
    <h4>
      <span class="text-success">
        {{ currency }}&nbsp;{{ balance.toLocaleString() }} </span
      >
    </h4>
  
      <b-button @click="pay_farmer" variant="success" class="btn-sm"
      v-analytics:click="['dashboard_pay_farmer',{element:'Suppliers'}]"
        ><b-icon-check></b-icon-check> Pay Now</b-button
      >
    

  </div>
</template>
<script>
import { get_pending_balance } from "./actions.js";
import { bus } from "@/main.js";

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed:{
    url(){
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  data() {
    return {
      balance: 0,
      currency: "UGX",
      farmer_id: "",
    };
  },
  methods: {
    get_pending_balance,
    pay_farmer() {
      this.info['pay_now']=true;
      bus.$emit("pay-farmer", this.info);
    },
  },
  mounted() {
    this.name = this.item.name;
    this.farmer_id = this.item.farmer_id;
    this.get_pending_balance();
  },
};
</script>
