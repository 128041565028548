import { axiosApiInstance } from "@/plugins/axios";

function pay_farmer() {
  this.loading = true;
  this.payment_info = {};
  let names = this.info.account_name.split(' ');
  let first_name = names[0];
  let other_names = names.slice(1).join(' ');

  let phone = Number(this.info.contact).toString();

  let code = phone.startsWith('256')?'':'256';
  let contact = code+phone;

  this.payment_info =
    this.select_method == "mobile"
      ? {
          first_name: first_name,
          last_name:other_names,
          phonenumber: contact,
          payment_method: "Mobile Money",
          "metadata,method": "money",
          "metadata,phone": contact,
        }
      : {
          account_name: this.info.account_name,
          account_number: this.info.account_number,
          phonenumber: contact,
          payment_method: "bank",
          bank: this.select_bank,
          "metadata,method": "bank",
          "metadata,phone": contact,
          destination_branch_code: this.banks.filter(
            (bank) => String(bank.value) === String(this.select_bank)
          )[0].destination_branch_code

        };

  let data = {
    pin: this.info.pin,
    payment_method: this.payment_info,
    farmer_id: this.info.farmer_id,
    amount: this.info.payment,
    farmers: {},
  };
  data.farmers[this.info.farmer_id] = this.info.payment;

  this.loading = true;
  this.payment_not_started = false;
  axiosApiInstance({
    url: this.url+"/payments/schedules/instant_payment/",
    method: "post",
    data: JSON.stringify(data),
  })
    .then((res) => {
      this.loading = false;
      
      console.log(res.data.status)
      this.success = true;
      setTimeout(() => {
        this.success = false;
        // this.$router.go();
        this.$emit("SuccessfulPayment");
        this.$bvModal.hide("pay-farmer");
      }, 2000);
    })
    .catch((error) => {
      this.loading = false;
      this.error = true;
      console.log(error);
      this.$bvModal.hide("pay-farmer");
      setTimeout(() => {
        this.$emit("SuccessfulPayment");
        this.$bvModal.hide("pay-farmer");
      }, 5000);
    });
}

function get_payment_info() {
  this.loading = true;

  let data = {
    farmer_id: this.info.farmer_id,
  };
  axiosApiInstance({
    url: this.url+"/payments/user/details/",
    method: "get",
    params: data,
  })
    .then((res) => {
      this.loading = true;
      let details = res.data.data;
      for (var key in details) {
        this.info[key] = details[key];
      }
      
      let contact = details.momo_phone?
      details.momo_phone:details.phonenumber;
      contact?this.info.contact=contact:0;
      let method = (details['metadata,method'])?details['metadata,method'].toLowerCase():'mobile';
      method=method?method.toLowerCase():method;
      this.select_bank=details.bank;
      if(method==='bank'){
        this.loading = true;
        this.select_method=method;

      }else{
        this.select_method='mobile';
        if (details.momo_first_name){
          this.info.account_name =  
          details.momo_first_name + " " + details.momo_last_name;
        }else{
          this.info.account_name=this.info.farmer_name;
        }
        
      }
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
  // axios.get
}

function get_banks() {
  this.loadingbanks = true;
  axiosApiInstance({
    url: this.url+"/payments/user/banks/",
    method: "get",
  })
    .then((res) => {
      this.banks = res.data.data;

      this.loadingbanks = false;
    })
    .catch((error) => {
      console.log(error);
      this.loadingbanks = false;
    });
  // axios.get
}

function save_farmer_amount() {
  this.loading = true;
  this.payment = {};
  let names = this.info.account_name.split(' ');
  let first_name = names[0];
  let other_names = names.slice(1).join(' ');

  let phone = Number(this.info.contact).toString();

  let code = phone.startsWith('256')?'':'256';
  let contact = code+phone;
  this.payment_info =
    this.select_method == "mobile"
      ? {
          first_name: first_name,
          last_name: other_names,
          phonenumber: contact,
          payment_method: "Mobile Money",
          "metadata,method": "money",
          "metadata,phone": contact,
        }
      : {
          account_name: this.info.account_name,
          account_number: this.info.account_number,
          phonenumber: contact,
          payment_method: "bank",
          bank: this.select_bank,
          "metadata,method": "bank",
          "metadata,phone": contact,
          destination_branch_code: this.banks.filter(
            (bank) => String(bank.value) === String(this.select_bank)
          )[0].destination_branch_code
        };

  this.loading = true;

  this.payment_not_started = false;

  let data = {
    payment_method: this.payment_info,
    farmer_id: this.info.farmer_id,
    amount: this.info.payment,
    farmers: {},
  };
  data.farmers[this.info.farmer_id] = this.info.payment;
  axiosApiInstance({
    url: this.url+"/payments/user/amount/",
    method: "put",
    data: JSON.stringify(data),
  })
    .then(() => {

      this.loading = false;

      this.success = true;
      this.$vToastify.success("Successfully updated!");
      setTimeout((res) => {
        console.log(res.data.status)
        this.success = false;
        
        this.$emit("SuccessfulPayment",
        {ac_name:this.payment_info.account_name?
          this.payment_info.account_name:
          this.payment_info.first_name+ ' '+
          this.payment_info.last_name,
        ac_number: this.payment_info.account_number?
        this.payment_info.account_number: 
        this.payment_info.phonenumber,
        amount: this.info.payment,
        method:this.payment_info['metadata,method']=='bank'?
        this.payment_info['metadata,method']:'mobile'

        });
        this.$bvModal.hide("pay-farmer");
      }, 2000);
    })
    .catch((error) => {
      this.loading = false;
      this.error = true;
      console.log(error);
      this.$emit("SuccessfulPayment");
      this.$vToastify.error(error.data.message);
      setTimeout(() => {
        this.$router.push('#');
        
        this.$bvModal.hide("pay-farmer");
      }, 5000);
    });
}

function send_verification() {
  this.loading = true;

  axiosApiInstance({
    url: this.url+"/payments/send_otp/",
    method: "post",
  })
    .then((res) => {
      console.log(res.data.status);
      this.loading = false;
      this.verified = true;
      this.waited = 1;
      setTimeout(() => {
        this.waited = 0;
      }, 120000);
    })
    .catch((error) => {
      this.loading = false;
      this.error = true;
      this.verified = false;
      console.log(error);
    });
}

export {
  pay_farmer,
  get_payment_info,
  get_banks,
  save_farmer_amount,
  send_verification,
};
