<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="edit-contract"
      title="Edit Contract"
      centered
      ok-title="Save Contract"
      @ok="saveData"
    >
      <label class="control-label">Contract Number</label>
      <input class="form-control" placeholder="" />
      <label class="control-label">Crop</label>
      <select class="form-control" v-model="crop">
        <option value="">Select</option>
        <option v-for="(c, i) in crops" :key="i" :value="c.name">
          {{ c.name }}
        </option>
      </select>
      <label class="control-label">Contract Date</label>
      <input class="form-control datepicker" placeholder="" type="date" />

      <label class="control-label">Quantity</label>
      <div class="input-group">
        <input class="form-control" />
        <span class="input-group-text">Kg</span>
      </div>
      <label class="control-label">Provisional Price per Kg</label>
      <div class="input-group">
        <input class="form-control" />
        <span class="input-group-text">UGX</span>
      </div>

      <label class="control-label d-block">Attachement</label>
      <label
        v-if="!files.name"
        class="btn btn-outline-dark btn-sm fw-normal mt-0"
        for="file"
      >
        <input
          class="d-none"
          id="file"
          placeholder=""
          type="file"
          @change="previewFile"
        />
        <i class="uil uil-paperclip"></i> Add File (Image/PDF)
      </label>
      <div v-if="files.name" class="bg-light-1 rounded p-2">
        <div class="row m-0">
          <div class="col-auto"><i class="uil uil-file"></i></div>
          <div class="col">{{ files.name }}</div>
          <div class="col-auto">
            <a
              class="uil uil-times close"
              @click="removeFile"
              title="Remove File"
            ></a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      crop: "",
      files: [],
      crops: [
        { name: "Chia" },
        { name: "Cabbage" },
        { name: "Carrots" },
        { name: "Beans" },
      ],
    };
  },
  methods: {
    previewFile(event) {
      this.files = event.target.files[0];
    },
    removeFile() {
      this.files = [];
    },
    saveData() {
      // send to database
      alert("contract saved");
    },
  },
};
</script>
<style lang="scss" scoped->
.bg-light-1 {
  background-color: #ddd;
}
</style>