import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";

function exact_time(time){
  return moment(time,"YYYY-MM-DD HH:mm:ss").format('ddd MMM Do YYYY, h:mm:ss a');
}
function get_payment_data() {
  this.loading = true;

  let data = {
    farmer_id: this.farmer_id
  };
  axiosApiInstance({
    url: this.url+"/payments/user/",
    method: "get",
    params: data,
  })
    .then((res) => {

      this.items = res.data.data;
      // console.log(res.status);
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
  // axios.get
}

function get_user_amount() {
  this.loadingPendingPayments = true;

  let data = {
    farmer_id: this.farmer_id
  };
  axiosApiInstance({
    url: this.url+"/payments/user/amount/",
    method: "get",
    params: data,
  })
    .then((res) => {
      this.pending_balance = res.data.data;
      // console.log(res.status);
      this.loadingPendingPayments = false;
    })
    .catch((error) => {
      this.loadingPendingPayments=false;
      console.log(error);
    });
  // axios.get
}
function format_num(num){
  return num?Number(num).toLocaleString():'-'
}


export { 
  get_payment_data, get_user_amount, 
  exact_time, format_num,
};
