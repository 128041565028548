import $ from "jquery";
import axios from "axios";
function checkPropData() {
    if ($.isEmptyObject(this.userData)) {
      this.supplier_type = "";
      this.form.supplier = "";
      this.form.contact = "";
      this.form.district = "";
      this.advance_items = [];
      this.farmerId = "";
      this.form.village = "";
    } else {
      this.isError = false;
      if ("user_type" in this.userData.data) {
        this.supplier_type = this.userData.data.user_type;
      } else {
        this.supplier_type = "farmer";
      }
      this.loadingAdvanceData = true;
      var self = this;
      var profileData = {};
      axios
        .get(process.env.VUE_APP_BACKEND + "/advances/user", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
          params: {
            user_type: this.supplier_type,
            user_id: this.userData.farmer_id,
            project_id: this.project_id,
          },
        })
        .then(function (response) {
          profileData = response.data.data;
          self.farmerId = self.userData.farmer_id;
          self.form.supplier = self.userData.name;
          self.isAutoComplete = false;
          self.form.contact = profileData.contact;
          self.form.district = profileData.district;
          self.form.village = profileData.village;
          self.loadingAdvanceData = false;
        });
      // console.log("This is the prop data: "+ JSON.stringify(this.userData));
    }
  }
function getUsers() {
    var self = this;
    self.loading = true;
    var supplier_type = this.supplier_type;
    axios
      .get(process.env.VUE_APP_BACKEND + "/advances/users", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
        params: { project_id: this.project_id, user_type: supplier_type },
      })
      .then((response) => {
        // data = response.data.data;
        self.userDetails = response.data.data;
        self.$vToastify.info(
          "Type a name in the supplier field and select/pick a name from list of possible names that will be displayed"
        );
        self.loading = false;
      });
  }
function getFulluser() {
    this.isError = false;
    for (const x of this.userDetails) {
      if (x.name === this.form.supplier) {
        this.form.district = x.district;
        this.form.contact = x.contact;
        this.form.village = x.village;
        this.farmerId = x.user_id;
      }
    }
  }
function editQuantity(data, context) {
    var index = data.index;
    if (context.quantity === 0) {
      // alert("Quantity can not be 0");
      this.$vToastify.error("Quantity can not be 0");
    } else {
      this.advance_items[index].amount = context.quantity * context.price;
      this.total_amount += this.advance_items[index].amount;
      this.$refs.advanceTableTest.refresh();
    }
  }
function giveInput() {
    this.loadingInputSubmission = true;
    if (this.advance_items.length === 0) {
      this.loadingInputSubmission = false;
      this.$vToastify.error(
        "Please ensure you have selected inputs to give."
      );
    } else if (this.isError) {
      this.loadingInputSubmission = false;
      this.$vToastify.error(
        "Please ensure you have selected a supplier from the dropdown list of supplier names"
      );
    } else {
        var dataObject = {
            user_type: this.supplier_type,
            username: this.form.supplier,
            contact: this.form.contact,
            district: this.form.district,
            project_id: this.project_id,
            village: this.form.village,
            user_id: this.farmerId,
            advances: this.advance_items,
          };
        this.$emit("Submit",dataObject);
    }
    
}
export {
    giveInput,editQuantity,getFulluser,
    getUsers, checkPropData,
}