import { axiosApiInstance } from "@/plugins/axios";
function get_pending_balance() {
  this.loading = true;
  let params = { farmer_id: this.farmer_id };
  axiosApiInstance({
    url: this.url+"/payments/user/amount/",
    method: "get",
    params,
  })
    .then((res) => {
      this.info = res.data.data ? res.data.data[0] : {};
      this.balance = this.info.amount;
      // console.log(res.status);

      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
    });
  // axios.get
}
export { get_pending_balance };
