<template>
  <b-modal
    :id="modal_id"
    size="xl"
    title="Record New Produce"
    @close="onReset"
    hide-footer
  >
    <b-form @submit="add_produce_record" @reset="onReset">
      <b-row>
        <div class="col-sm-4">
          <b-container>
            <b-form-group
              description="Select Supplier type"
              invalid-feedback="Invalid supplier type"
            >
              <template #label>
                Supplier Type <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="$v.form.supplier_type.$model"
                :options="supplier_types"
                @change="selectSupplierType"
                :state="validateState($v.form.supplier_type)"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              label="Supplier"
              label-for="phone"
              description="Enter Supplier Name"
              id="supplierValue"
              v-if="autocomplete"
            >
              <vue-bootstrap-typeahead
                :data="suppliers"
                v-model="form.supplier"
                :serializer="(s) => s.name"
                placeholder="Specify supplier"
                @hit="onSupplierSelect()"
                ref="supplierValue"
              ></vue-bootstrap-typeahead>
            </b-form-group>
            <b-form-group
              label="Supplier"
              label-for="phone"
              description="Enter Supplier Name"
              id="supplierParsed"
              v-if="!autocomplete"
            >
              <b-form-input
                v-model="form.supplier"
                type="text"
                ref="supplierParsed"
              ></b-form-input>
            </b-form-group>

            <b-form-group invalid-feedback="Invalid Phone No.">
              <template #label>
                Contact <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="$v.form.contact.$model"
                id="bio_contact"
                type="tel"
                :state="validateState($v.form.contact)"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="grn"
              description="Enter GRN Number"
              invalid-feedback="GRN Number is required"
            >
              <template #label>
                GRN Number <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="$v.form.receipt_number.$model"
                type="text"
                :state="validateState($v.form.receipt_number)"
              ></b-form-input>
            </b-form-group>

            <b-form-group invalid-feedback="District is required">
              <template #label>
                District <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="$v.form.district.$model"
                type="text"
                :state="validateState($v.form.district)"
              ></b-form-input>
            </b-form-group>
            <div class="row">
              <!-- <b-form-group
              class="col"
              label="Payment Mode Consent"
              label-for="payment_mode_consent"
            >
              <input
                id="file1" 
                ref="file1" 
                @change="handleFileUpload()"
                accept="image/*"
                type="file"
              />
            </b-form-group> -->

              <b-form-group
                class="col"
                label="Goods Received Note"
                label-for="produce_receipt"
              >
                <b-form-file
                  v-model="form.produce_receipt"
                  :state="Boolean(form.produce_receipt)"
                  accept="image/*"
                  placeholder="Select a picture of produce receipt..."
                  drop-placeholder="Drop file here..."
                ></b-form-file>
              </b-form-group>
            </div>
            <div v-show="form.supplier_type == 'Farmer'">
              <div class="container-fluid" v-if="loadingVA">
                <b-spinner> </b-spinner
                ><small class="text-muted">&nbsp;&nbsp;Loading Agent...</small>
              </div>

              <div v-if="!loadingVA">
                <b-form-group label="Agent Name">
                  <b-form-input
                    v-model="form.agent_name"
                    type="text"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Agent Phone">
                  <b-form-input
                    type="tel"
                    v-model="form.agent_phone"
                    pattern="^07[0-9]{8}"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
          </b-container>
        </div>
        <div class="col-sm-8 border-left">
          <div class="container">
            <div class="row">
              <b-form-group
                class="col"
                description="Select Crop"
                invalid-feedback="Please specify the crop"
              >
                <template #label>
                  Crop <span class="text-danger">*</span>
                </template>
                <b-form-select
                  v-model="$v.form.value_chain.$model"
                  :options="crops"
                  @change="onSelectingCrop"
                  :state="validateState($v.form.value_chain)"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                class="col"
                description="Select Variety"
                invalid-feedback="Variety is required"
              >
                <template #label>
                  Variety <span class="text-danger">*</span>
                </template>
                <b-form-select
                  v-model="$v.form.variety.$model"
                  :options="variety"
                  @change="onVarietySelect"
                  :state="validateState($v.form.variety)"
                ></b-form-select>
              </b-form-group>
              <!-- <b-form-group
                class="col"
                label="Graded?"
                label-for="graded"
                description="Graded?"
              >
                <b-form-select
                  v-model="form.graded"
                  :options="graded"
                  required
                ></b-form-select>
              </b-form-group> -->
            </div>
            <div class="row">
              <b-form-group
                class="col"
                label-for="gross_weight"
                invalid-feedback="Gross Weight is invalid"
              >
                <template #label>
                  Gross Weight(Kgs) <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model.number="$v.form.gross_weight.$model"
                  id="gross_wgt"
                  type="number"
                  :state="validateState($v.form.gross_weight)"
                ></b-form-input>
              </b-form-group>
              <b-form-group class="col">
                <template #label>
                  Net Weight(Kgs) <span class="text-danger">*</span>
                </template>
                <b-form-invalid-feedback
                  :state="
                    validateState($v.form.net_weight) &&
                    !serverErrors.net_weight.error
                  "
                >
                  <span v-show="$v.form.net_weight.$error"
                    >Must be greater than zero.</span
                  >
                  <span v-show="serverErrors.net_weight.error"
                    >Cannot be greater than gross weight
                  </span>
                </b-form-invalid-feedback>
                <b-form-input
                  v-model.number="$v.form.net_weight.$model"
                  type="number"
                  :state="
                    validateState($v.form.net_weight) &&
                    !serverErrors.net_weight.error
                  "
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col"
                label-for="unit_price"
                invalid-feedback="Unit Price is required"
              >
                <template #label>
                  Unit Price <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model.number="$v.form.unit_price.$model"
                  type="number"
                  :state="validateState($v.form.unit_price)"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="row">
              <b-form-group
                class="col"
                label-for="moisture"
                invalid-feedback="Moisture Content is required"
              >
                <template #label>
                  Moisture (%) <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model.number="$v.form.moisture_content.$model"
                  type="number"
                  step=".001"
                  :state="validateState($v.form.moisture_content)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col"
                invalid-feedback="Invalid Foreign Matter"
              >
                <template #label>
                  Foreign Matter(Kgs) <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model.number="$v.form.foreign_matter.$model"
                  type="number"
                  step=".001"
                  :state="validateState($v.form.foreign_matter)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Total Amount"
                class="col"
                label-for="total_amount"
                description=""
              >
                <b-form-input
                  :value="form.net_weight * form.unit_price"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            <b-form-group label="Notes">
              <b-form-textarea
                id="textarea"
                v-model="form.notes"
                placeholder="Enter something..."
                rows="3"
              ></b-form-textarea>
            </b-form-group>
            <b-row>
              <b-form-group class="col" description="Select Payment type">
                <template #label>
                  Payment Type <span class="text-danger">*</span>
                </template>
                <b-form-select
                  v-model="$v.form.method.$model"
                  @change="form.paymentInfoUpdated = true"
                  :options="payment_methods"
                  :state="validateState($v.form.method)"
                ></b-form-select>
              </b-form-group>
              <b-col>
                <b-form-group
                  v-show="form.method === 'bank'"
                  label="Select Bank"
                  label-for="banks"
                  class="col"
                  invalid-feedback="Select Recipient's Bank"
                >
                  <template> </template>
                  <b-form-select
                    v-model="$v.form.bank.$model"
                    :state="validateState($v.form.bank)"
                    :options="banks"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Please select a bank --</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="row" v-show="form.method === 'mobile'">
              <b-form-group
                class="col"
                description="Enter Registered First Name"
                invalid-feedback="First Name is required"
              >
                <template #label>
                  Registered First Name <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.form.reg_fname.$model"
                  @keyup="onPaymentFieldsEdit"
                  type="text"
                  :state="validateState($v.form.reg_fname)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col"
                description="Enter Registered Last Name"
                invalid-feedback="Last Name is required"
              >
                <template #label>
                  Registered Last Name <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="form.reg_lname"
                  @keyup="onPaymentFieldsEdit"
                  type="text"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col"
                description="Enter Registered Number 07xxxxxxxx"
                invalid-feedback="Invalid/Missing Phone Number"
              >
                <template #label>
                  Registered Number <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.form.reg_number.$model"
                  id="reg_contact"
                  @keyup="onContactMutation"
                  type="tel"
                  :state="validateState($v.form.reg_number)"
                ></b-form-input>
                <p class="" id="contact_error"></p>
              </b-form-group>
            </div>

            <div class="row" v-show="form.method === 'bank'">
              <b-form-group
                class="col"
                label-for="acc_name"
                invalid-feedback="Account Name is required"
              >
                <template #label>
                  Account Name <span class="text-danger">*</span>
                </template>
                <b-form-input
                  v-model="$v.form.account_name.$model"
                  type="text"
                  :state="validateState($v.form.account_name)"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col"
                label-for="acc_number"
                description="Enter Recipient's Account Number"
                invalid-feedback="Account Number is required"
              >
                <template #label>
                  Account Number <span class="text-label">*</span>
                </template>
                <b-form-input
                  v-model="$v.form.account_number.$model"
                  :state="validateState($v.form.account_number)"
                  type="text"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Phone Number"
                class="col"
                label-for="reg_number"
                description="Enter Recipient's Number 07xxxxxxxx"
              >
                <b-form-input
                  v-model="$v.form.reg_number.$model"
                  id="reg_contact"
                  type="tel"
                  :state="validateState($v.form.reg_number)"
                ></b-form-input>
                <p class="" id="contact_error"></p>
              </b-form-group>
            </div>

            <div class="row">
              <div class="col-sm-12 float-right">
                <b-button
                  type="submit"
                  class="btn-sm"
                  v-analytics:click="[
                    'save_new_produce',
                    { element: 'Produce' },
                  ]"
                  variant="primary"
                >
                  Save
                </b-button>
                <loader :isLoading="loading" />
              </div>
            </div>
          </div>
        </div>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>
import {
  getCurrentSeason,
  display_payment_data,
  onContactMutation,
  handleFileUpload,
  onPaymentFieldsEdit,
  setNetWeight,
  onSelectingCrop,
  onVarietySelect,
  checkVariety,
  varietyForSelectedCrop,
  onSupplierSelect,
  selectSupplierType,
  getPaymentInfo,
  getCrops,
  checkFarmerCropProp,
  fetchData,
  onReset,
  add_produce_record,
  get_banks,
} from "./actions.js";
import Loader from "../Loader";

import { bus } from "@/main.js";

import { validationMixin } from "vuelidate";
import { rvalidator } from "./validators";
export default {
  components: { Loader },
  props: {
    farmerSelected: {
      type: Object,
      default: function () {
        return {};
      },
    },
    selectedCrop: {
      type: String,
      default: "",
    },
    modal_id: {
      type: String,
      default: "default",
    },
  },
  mixins: { validationMixin },
  validations: {
    form: rvalidator,
  },
  data() {
    return {
      form: {
        value_chain: null,
        variety: "",
        gross_weight: 0,
        net_weight: 0,
        unit_price: 0,
        moisture_content: "",
        foreign_matter: "",
        reg_fname: "",
        reg_lname: "",
        reg_number: "",
        supplier_type: null,
        supplier: "",
        district: "",
        bank: null,
        contact: "",
        amount_used: 0,
        method: "mobile",
        produce_receipt: null,
        paymentInfoPresent: false,
        paymentInfoUpdated: false,
        project_id: null,
        season: getCurrentSeason(),
        notes: "",
        agent_name: "",
        receipt_number: "",
        agent_phone: "",
      },
      file1: null,
      file2: null,
      supplier_types: [
        { text: "Select Supplier type", value: null },
        "Farmer",
        "Agent",
        "Farmer Group",
      ],
      payment_methods: [
        { text: "Mobile", value: "mobile" },
        { text: "Bank", value: "bank" },
        { text: "Cash", value: "cash" },
      ],
      crops: [{ text: "Select Crop", value: null }],
      mas: [],
      vas: [],
      farmers: [],
      baseURI: process.env.VUE_APP_BACKEND,
      token: localStorage.getItem("access_token"),
      suppliers: [],
      season: [],
      variety: [],
      cropsData: [],
      paymentDetails: [],
      loading: false,
      autocomplete: true,
      cropSelected: this.selectedCrop,
      farmerViewOpen: false,
      banks: [],
      selected: this.farmerSelected,
      loadingVA: false,
      loadingfarmerData: false,
      serverErrors: {
        net_weight: {
          error: false,
        },
      },
    };
  },
  methods: {
    get_banks,
    display_payment_data,
    onContactMutation,
    handleFileUpload,
    onPaymentFieldsEdit,
    setNetWeight,
    onSelectingCrop,
    onVarietySelect,
    checkVariety,
    varietyForSelectedCrop,
    onSupplierSelect,
    selectSupplierType,
    getPaymentInfo,
    getCrops,
    checkFarmerCropProp,
    fetchData,
    onReset,
    add_produce_record,
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    onProdBulk(){
      if(this.form.supplier_type=="Farmer"){
        bus.$emit("farmer-bulk",this.form.farmer_id);
      }
    }
  },
  mounted: function () {
    this.fetchData(this);
    this.getPaymentInfo(this);
    this.getCrops(this);

    this.get_banks();
    bus.$on("set-crop", (data) => {
      this.form.value_chain = data.crop;
      this.form.supplier_type = "Farmer";
      this.form.supplier = data.farmer;
      this.autocomplete = false;
      this.selectSupplierType();
      this.onSupplierSelect();
      this.onSelectingCrop();
      this.farmerViewOpen = true;
    });

    // this.checkFarmerCropProp();
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
    username() {
      return this.$store.state.username;
    },
    url() {
      return `${this.baseURI}/api/v3${this.$store.state.url_prefix}/produce`;
    },
    url_prefix() {
      return this.$store.state.url_prefix;
    },
  },
};
</script>

<style>
.show {
  display: block;
}
.hide {
  display: none;
}
.height-20 {
  max-height: 20vh;
}
/* .border-right {
  border-left: gray 1px solid;
} */
.float-right {
  float: right;
  text-align: right;
}
.blur {
  filter: blur(0.7rem);
}
</style>
