<template>
  <div>
    <!-- The modal -->
    <b-modal
      id="edit-bio"
      title="Edit Bio"
      ok-title="Save Changes"
      @ok="saveChanges"
    >
      <label class="control-label">Full Name</label>
      <input class="form-control" placeholder="" />
      <label class="control-label">Gender</label>
      <select class="form-control">
        <option>Male</option>
        <option>Female</option>
      </select>
      <label class="control-label">Date of Birth</label>
      <input class="form-control datepicker" placeholder="" type="date" />
      <label class="control-label">Disability</label>
      <select class="form-control">
        <option>None</option>
        <option>Blind</option>
        <option>Deaf</option>
        <option>Lame</option>
      </select>

      <label class="control-label">Country</label>
      <select class="form-control">
        <option>Uganda</option>
        <option>Algeria</option>
        <option>Argentina</option>
        <option>Armenia</option>
      </select>

      <label class="control-label">In Country status</label>
      <select class="form-control">
        <option>National</option>

        <option>Refugee</option>
      </select>

      <label class="control-label">MAAIF Code</label>
      <input class="form-control datepicker" placeholder="" type="text" />
    </b-modal>
  </div>
</template>
<script>
export default {
  methods: {
    saveChanges() {
      // send to database
      alert("contract saved");
    },
  },
};
</script>