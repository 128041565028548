import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";

function get_user_training_sessions() {
  this.loading = true;
  axiosApiInstance({
    url: this.url,
    method: "get",
    params:{
        user_id:this.item.farmer_id
    }
  })
    .then((res) => {
      this.items = res.data.data;
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
}
function format_date(date) {
    return date ? moment(date).format("LL") : "-";
}
function view_training(training) {
  this.$router.push({
    name: "training-session",
    params: {
      training_id: training.training_id,
    },
  });
}

export { get_user_training_sessions, format_date, view_training };
