<template>
  <b-modal
    id="delete-produce"
    title="Delete Produce"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
  >
    <b-container v-show="can('approve', 'payments')">
      <h5 class="text-dark font-weight-light">
        Are you sure you want to DELETE&nbsp;
        {{ selectedProduce.farmer_name }}'s&nbsp; Produce?
      </h5>
      <hr />
      <b-row>
        <b-col class="text-dark">
          Value Chain
        </b-col>
        <b-col>
          <span class=" float-right">
            {{ selectedProduce.crop }}
          </span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="text-dark">
          Net Weight
        </b-col>
        <b-col>
          <span class="text-success float-right">
            {{
              selectedProduce.net_weight
                ? selectedProduce.net_weight
                : selectedProduce.gross_weight
            }}
          </span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="text-dark">
          Gross Weight
        </b-col>
        <b-col>
          <span class="float-right">
            {{ selectedProduce.gross_weight }}
          </span>
        </b-col>
      </b-row>
      <hr />
      <div>
        <b-container v-show="isloading">
            <b-row class="d-flex justify-content-center mb-3">
            <span class="font-weight-light text-muted"
                >Deleting...&nbsp;&nbsp;</span
            >
            <b-spinner variant="secondary" label="Loading..."></b-spinner>
            </b-row>
        </b-container>
        <b-row>
          <b-col v-if="!isloading" class="float-right">
            <b-button
              v-if="can('modify', 'produce')"
              class="float-right"
              @click="delete_produce"
              variant="outline-danger"
              v-analytics:click="[
                'delete_produce',
                { element: 'Delete Produce' },
              ]"
            >
              <b-icon-trash></b-icon-trash>
              &nbsp;Delete Produce
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import { can } from "@/modules/auth/utils.js";
import { delete_produce } from "./actions.js";
export default {
  props: {
    selectedProduce: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      isloading: false,
      success:false
    };
  },
  methods: {
    can,
    delete_produce,
  },
  mounted() {
      this.success=false;
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
    url() {
      return `${process.env.VUE_APP_BACKEND}/api/v3${this.$store.state.url_prefix}/produce`;
    },
  },
};
</script>
