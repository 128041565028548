import {axiosApiInst} from "../../plugins/axios"

const TEMPLATE_URL = process.env.VUE_APP_BACKEND+ "/api/v3/farmers/farmer-upload";

const BASE_URL = process.env.VUE_APP_BACKEND+"/api/v3/farmers";
async function getcsvtemplate(){
    const res =  await axiosApiInst(TEMPLATE_URL);
    return res;
}
/**
 * 
 * @param {string} farmer_id 
 */
async function get_gardens(farmer_id){
    const res = await axiosApiInst({
        url:BASE_URL+"/"+farmer_id+"/gardens"
    });
    return res.data;
}
async function get_short_profile(farmer_id){
    const res = await axiosApiInst({
        url:BASE_URL+"/single/short/"+farmer_id
    });
    return res.data;
}
export {getcsvtemplate,TEMPLATE_URL,get_gardens,get_short_profile}
