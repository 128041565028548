<template>
  <b-modal
    id="farmer-view"
    size="xl"
    hide-footer
    hide-header
    no-close-on-esc
    no-close-on-backdrop
    body-class="body p-0"
    no-fade
    centered
  >
    <div class="row m-0">
      <!-- tabs -->
      <div class="col-3 left p-0 border-right">
        <div class="farmer-card ">
          <table>
            <td>
              <div class="img">
                <b-avatar v-show="!photo_exists" icon="person">{{ name[0] }}</b-avatar>
                <b-img
                width="50px"
                height="50px"
                v-bind="imgProps"
                v-show="photo_exists" 
                :src="'https://storage.googleapis.com/ezyagric-media' +profile.farmer_photo"
                rounded="circle" alt=""/>

              </div>
            </td>
            <td>
              <h4 class="m-0">{{ name }}</h4>
              <p v-show="profile.farmer_district" class="m-0 text-muted text-small">{{profile.farmer_district}}, {{profile.farmer_village}}</p>
              <div v-show="false" class="text-small">
                <i class="text-success  uil uil-shield-check"></i> Organic
              </div>
            </td>
          </table>
        </div>
        <div class="tabs" role="tablist">
          <a
            class="tab-link"
            :class="{ active: tab == 'dashboard' }"
            @click="
              tab = 'dashboard';
              tabname = 'DASHBOARD';
            "
            >Dashboard</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'produce' }"
            @click="
              tab = 'produce';
              tabname = 'PRODUCE';
            "
            >Produce</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'farm_inputs' }"
            @click="
              tab = 'farm_inputs';
              tabname = 'FARM INPUTS';
            "
            >Farm Inputs</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'training' }"
            @click="
              tab = 'training';
              tabname = 'TRAINING';
            "
            >Training</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'payments' }"
            @click="
              tab = 'payments';
              tabname = 'PAYMENTS';
            "
            >Payments</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'profile' }"
            @click="
              tab = 'profile';
              tabname = 'FARMER DETAILS';
            "
            >Farmer Details</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'reports' }"
            @click="tab = 'reports'"
            v-show="false"
            >Reports</a
          >
          <a
            class="tab-link"
            :class="{ active: tab == 'monitoring' }"
            @click="
              tab = 'monitoring';
              tabname = 'MONITORING';
            "
            v-show="false"
            >Monitoring</a
          >
        </div>
      </div>

      <!-- tab content -->
      <div class="col-9 p-0">
        <b-row class=" m-0">
          <b-col>
            <div class="page-head d-flex align-items-center h-100">
              <h5 class="page-title m-0">{{ tabname }}</h5>
            </div>
          </b-col>
          <b-col cols="auto" v-show="true">
            <div class="d-flex align-items-center h-100">
              <button class="btn-opt primary">
                <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
                Refresh
              </button>
              <button class="btn-opt primary">
                <b-icon-printer></b-icon-printer> Print
              </button>
              <button class="btn-opt primary">
                <b-icon-book></b-icon-book> Export
              </button>
            </div>
          </b-col>
          <b-col col-auto class="col-sm-1">
            <button
              @click="close"
              type="button"
              class="close p-3"
              aria-label="Close"
            >
              <b-icon-x />
            </button>
          </b-col>
        </b-row>
        <div class="overflow-scroll modal-content-holder">
          <dashboard :item="item" v-if="tab == 'dashboard'"></dashboard>
          <produce
            :item="item"
            @GotoPayments="payments_tab"
            v-if="tab == 'produce'"
          ></produce>
          <farm-inputs
            @SuccessfulChange="loadFarmInputs"
            :item="item"
            v-if="tab == 'farm_inputs'"
          ></farm-inputs>
          <!-- <profile :item="item" v-show="tab == 'profile'" @SuccessfulEdit="onEdited"></profile> -->
          <payments
            :key="payments_view"
            @GotoProduce="produce_tab"
            :item="item"
            v-if="tab == 'payments'"
          ></payments>
          <reports :item="item" v-if="tab == 'reports'"></reports>
          <monitoring :item="item" v-if="tab == 'monitoring'"></monitoring>
          <training :item="item" v-if="tab == 'training'"></training>
          <farmer-info
            :farmer_name="name"
            :item="item"
            v-if="tab == 'profile'"
          ></farmer-info>
        </div>
      </div>
      <pay-farmer @SuccessfulPayment="reload"></pay-farmer>
      <pay-cash @SuccessfulPayment="reload"></pay-cash>
    </div>
  </b-modal>
</template>
<script>
import { bus } from "@/main.js";
import Dashboard from "./subcomponents/dashboard/Dashboard.vue";
// import Profile from "./subcomponents/profile/Profile.vue";
import FarmerInfo from "./subcomponents/farmer_info/FarmerInfo";
import Produce from "./subcomponents/produce/Produce.vue";
import Monitoring from "./subcomponents/monitoring/Monitoring.vue";
import FarmInputs from "./subcomponents/farm_inputs/FarmInputs.vue";
import Reports from "./subcomponents/reports/Reports.vue";
import Payments from "./subcomponents/payments/Payments.vue";
import PayFarmer from "../pay_farmer/PayFarmer.vue";
import PayCash from "../cash_payment/CashPayment.vue";
import Training from "./subcomponents/training/Training.vue";
import { get_short_profile } from "./api";

export default {
  name: "farmer-modal",
  components: {
    Dashboard,
    Monitoring,
    FarmInputs,
    Reports,
    Produce,
    // Profile,
    Payments,
    PayFarmer,
    PayCash,
    Training,
    FarmerInfo,
  },
  data() {
    return {
      name: "",
      tabname: "Personal Info",
      tab: "dashboard",
      obj: {},
      item: {},
      payments_view: 0,
      profile:{},
      isLoadingProfile:false,
      imgProps: { width: 50, height: 50, class: 'm1' }
    };
  },
  methods: {
    reload() {
      this.$emit("SuccessfulPayment");
      this.payments_view++;
    },
    loadFarmInputs() {
      this.$emit("reloadParentAdvance");
    },
    produce_tab() {
      this.tab = "produce";
    },
    payments_tab() {
      this.tab = "payments";
    },
    onEdited(name) {
      this.name = name;
    },
    close() {
      this.$emit("ModalClose");
      this.$bvModal.hide("farmer-view");
    },
    get_profile(){
      this.isLoadingProfile=true;
      get_short_profile(this.item.farmer_id).then((res)=>{
        this.profile=res.data;
        this.isLoadingProfile=false;
      })
    }
  },
  computed:{
    photo_exists(){
      return this.profile.farmer_photo && this.profile.farmer_photo!=='NA'
    }
  },
  mounted() {
    bus.$on("farmer-view", (payload) => {
      this.name = payload.name;
      payload.tab ? (this.tab = payload.tab) : null;
      this.farmer_id = payload.farmer_id;
      this.obj = payload;
      this.item = payload;
      this.$bvModal.show("farmer-view");
      this.get_profile();
    });
  },
};
</script>
<style lang="css" scoped>
.tabs {
  padding: 16px;
}
.tabs a {
  display: block;
  font-weight: 500;
  font-size: 15px;
  border-radius: 4pt;
  border-right: 4pt solid transparent;
  padding: 8px 16px;
  border-left: none;
  border-top: none;
  color: #838383;
  cursor: pointer;
  margin-bottom: 8px;
  text-decoration: none;
}

.tabs a.active {
  /* border-right-color: #3583c7; */
  color: #1f4564;
  background-color: #c7dff6;
}

/* .page-height-80{
    width:80vh
} */
/* .left {
  background-color: rgba(230, 231, 233, 0.849);
} */

.farmer-card .img {
  height: 50px;
  width: 50px;
  /* border-radius: 50px; */
  background-color: #dcdddd;
}
.farmer-card h4 {
  font-size: 15px;
}
.farmer-card {
  padding: 16px;
  background-color: #f5f5f5;
  margin: 16px;
  border-radius: 8px;
}

.farmer-card table {
  width: 100%;
}

.farmer-card table td:last-child {
  width: 100%;
  padding-left: 8px;
}
</style>
