<template>
  <apexchart
    type="bar"
    height="200"
    :options="chartOptions"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts },
  props: {
    chartOptions: Object,
    series: Array,
  },
  
};
</script>

<style></style>
