import { axiosApiInstance } from "@/plugins/axios";

function pay_farmer() {
  this.loading = true;
  let data = {
    pin: this.info.pin,
    farmer_id: this.info.farmer_id,
    amount: this.info.payment,
    farmers: {},
  };
  data.farmers[this.info.farmer_id] = this.info.payment;

  this.loading = true;
  this.payment_not_started = false;
  axiosApiInstance({
    url: this.url+"/payments/schedules/cash_payment/",
    method: "post",
    data: JSON.stringify(data),
  })
    .then((res) => {
      this.loading = false;
      
      console.log(res.data.status)
      this.success = true;
      this.$vToastify.success("Successfully Recorded!");
      this.$emit("SuccessfulPayment");
      setTimeout(() => {
        this.success = false;
        this.$bvModal.hide("cash-payment");
      }, 2000);
    })
    .catch((error) => {
      this.loading = false;
      this.error = true;
      console.log(error);
      this.$vToastify.error("Failed to Record payment!");
      this.$bvModal.hide("cash-payment");
      this.$emit("SuccessfulPayment");
    });
}

export {
  pay_farmer,
};
