import { pluck } from "@/modules/utils.js"
import moment from "moment";
import { SEASONS } from "../../../../modules/dashboard/constants.js";
const SEASON_DATES = {
    "A":{
        start_date:(year)=>{return `${year}-01-01`},
        end_date:(year)=>{return `${year}-06-30`}
    },
    "B":{
        start_date:(year)=>{return `${year}-07-01`},
        end_date:(year)=>{return `${year}-12-31`}
    }
}
function thisSeason(){
    const year = moment().year();
    const monthstr = moment().format("MM");
    const season = SEASONS[0].includes(monthstr)?"A":"B";
    const dates = SEASON_DATES[season]; 
    return {"start_date":dates.start_date(year),"end_date":dates.end_date(year)}
}
function lastSeason(){
    let year = moment().year();
    const monthstr = moment().format("MM");
    let season = SEASONS[0].includes(monthstr)?"A":"B";
    if(season=="A"){
        year-=1;
        season = "B";
    }else{
        season = "A"
    }
    const dates = SEASON_DATES[season];
    return {"start_date":dates.start_date(year),"end_date":dates.end_date(year)}
}
const FSEASONS = {
    "This Season":thisSeason,
    "Last Season":lastSeason
}
/**
 * 
 * @typedef {import("./api.js").TonnesAvailable} TonnesAvailable
 */
/**
 * 
 * @param {Array<TonnesAvailable>} data 
 * @returns {Promise<Array<number>>}
 */
 async function get_values(data) {
    return data.map(pluck("quantity_supplied"));
}
/**
 * 
 * @param {Array<TonnesAvailable>} data 
 * @returns {Promise<Array<string>>}
 */
async function get_labels(data) {
    return data.map(pluck("date_received"));
}

export {get_values,get_labels,FSEASONS}