import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";
import $ from "jquery";

function NameToCamelCase(fname) {
  if (typeof fname != "undefined") {
    if (fname.indexOf(" ") >= 0) {
      let _name = fname.split(" ");
      let new_name = [];
      _name.map((item) => {
        item = item.toLowerCase();
        item = item.charAt(0).toUpperCase() + item.slice(1);
        new_name.push(item);
      });
      fname = new_name.join(" ");
    } else if (fname.indexOf("/") >= 0) {
      let _name = fname.split("/");
      let new_name = [];
      _name.map((item) => {
        item = item.toLowerCase();
        item = item.charAt(0).toUpperCase() + item.slice(1);
        new_name.push(item);
      });
      fname = new_name.join("/");
    } else {
      fname = fname.toLowerCase();
      fname = fname.charAt(0).toUpperCase() + fname.slice(1);
    }
    return fname;
  }
}
function getCurrentSeason() {
  var current_date = new Date().date;
  var current_year = moment(current_date).format("Y"),
    current_month = moment(current_date).format("M");
  if (parseInt(current_month) < 10) current_month = "0" + current_month;

  var season_a_mons = ["01", "02", "03", "04", "05", "06", "07"];
  var season_b_mons = ["08", "09", "10", "11", "12"];

  if (season_a_mons.includes(current_month)) return current_year + "-A";
  else if (season_b_mons.includes(current_month)) return current_year + "-B";
}
function dateDifference(period, start_date) {
  start_date = moment(start_date).format("DD-MM-YYYY");
  var startDate = moment(start_date, "DD-MM-YYYY");
  const current_date = moment(new Date()).format("DD-MM-YYYY");
  var endDate = moment(current_date, "DD-MM-YYYY");
  var dayDiff = 0;
  var duration = "";

  switch (period) {
    case "days":
      dayDiff = endDate.diff(startDate, "days");
      if (dayDiff > 30) {
        dayDiff = endDate.diff(startDate, "months");
        duration = dayDiff.toString() + " months";
      }
      period = dayDiff > 1 ? period : "day";
      duration = dayDiff.toString() + " " + period;
      break;
    case "months":
      dayDiff = endDate.diff(startDate, "months");
      duration = dayDiff.toString() + " " + period;
      break;
  }
  return duration;
}
function setInputFilter(textbox, inputFilter) {
  [
    "input",
    "keydown",
    "keyup",
    "mousedown",
    "mouseup",
    "select",
    "contextmenu",
    "drop",
  ].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if ("oldValue" in this) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      }
    });
  });
}

function display_payment_data(data, item) {
  let methods = {
    mobile: () => {
      if (!data["momo_first_name"]) {
        this.form.account_name = item["name"];
        let names = item["name"].split(" ");
        let first_name = names[0];
        let other_names = names.slice(1).join(" ");
        this.form.reg_fname = first_name;
        this.form.reg_lname = other_names;
        this.form.reg_number = item["contact"];
      } else {
        this.form.reg_fname = data["momo_first_name"];
        this.form.reg_lname = data["momo_last_name"];
        this.form.reg_number = data["momo_phone"];
        this.form.method = "mobile";
      }
    },
    bank: () => {
      this.form.account_name = data["account_name"];
      this.form.account_number = data["account_number"];
      this.form.reg_number = data["momo_phone"];
      this.form.bank = data["bank"];
      this.form.method = "bank";
    },
  };
  methods[data["payment_method"]]
    ? methods[data["payment_method"]]()
    : methods.mobile();
}

function onContactMutation() {
  if (this.form.paymentInfoPresent) {
    this.form.paymentInfoUpdated = true;
  }
}

function handleFileUpload() {
  this.file1 = this.$refs.file1.files[0];
  this.file2 = this.$refs.file2.files[0];
}

function onPaymentFieldsEdit() {
  if (this.form.paymentInfoPresent) {
    this.form.paymentInfoUpdated = true;
  }
}

function setNetWeight() {
  let gross_weight = parseFloat(this.form.gross_weight);
  if (this.form.value_chain.toLowerCase() === "maize cob") {
    this.form.net_weight = (0.77 * gross_weight).toFixed(2);
  } else {
    this.form.net_weight = gross_weight.toFixed(2);
  }
}

function onSelectingCrop() {
  this.variety = this.cropsData
    .filter(this.varietyForSelectedCrop)
    .map((crop) => {
      return crop.variety;
    });
  this.form.variety = this.variety[0];
  this.onVarietySelect();
  this.setNetWeight();
}

function onVarietySelect() {
  let variety = this.cropsData.filter(this.checkVariety);
  this.form.unit_price = variety[0]["unit_price"];
  this.form.units = variety[0]["unit"];
}

function checkVariety(item) {
  return item.variety === this.form.variety;
}

function varietyForSelectedCrop(crop) {
  return (
    crop["crop"].trim().toLowerCase() === this.form.value_chain.toLowerCase()
  );
}

function onSupplierSelect() {
  const registered_ids = Object.keys(this.paymentDetails);
  this.suppliers.map((item) => {
    if (item["name"].toLowerCase() === this.form.supplier.toLowerCase()) {
      this.form.contact = item["contact"];
      this.form.district = item["district"];
      $("#bio_contact").val(item.district);
      let user_id = item["id"];
      this.form.farmer_id = user_id;
      this.form.farmer_name = item["name"];
      this.form.user_type = this.form.supplier_type;
      this.form.ma_id = item["ma_id"];
      if (this.form.supplier_type !== "Farmer") {
        user_id = user_id.replace("/", "-");
      }
      if (this.form.supplier_type !== "Agent") {
        this.form.vaId = item["vaId"];
        findVa(item["vaId"], this);
      }
      if (this.form.supplier_type === "Farmer Group") {
        this.form.ma_id = item["id"];
        this.form.user_type = "farmer_group";
        this.form.user_id = user_id;
        this.form.vaId = ""
      } else {
        this.form.user_type = "va";
        this.form.user_id = item["vaId"];
        this.form.vaId = item["vaId"];
      }
      // ==== populating payment info present ==== //
      if (registered_ids.length !== 0) {
        if (registered_ids.includes(user_id)) {
          this.form.paymentInfoPresent = true;
          let value = this.paymentDetails[user_id];
          this.display_payment_data(value, item);
        } else {
          this.form.paymentInfoPresent = false;
          this.display_payment_data({}, item);
        }
      }
    }
  });
}

function selectSupplierType() {
  if (this.form.supplier_type === "Farmer") {
    this.suppliers = this.farmers;
  } else if (this.form.supplier_type === "Agent") {
    this.suppliers = this.vas;
  } else if (this.form.supplier_type === "Farmer Group") {
    this.suppliers = this.mas;
  }
}

function getPaymentInfo(dataObj) {
  axiosApiInstance
    .get(this.url + "/get-payment-details")
    .then((result) => {
      if (result.data.success) {
        dataObj.paymentDetails = result.data.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

function getCrops(dataObj) {
  dataObj.parameters = {
    project_id: this.projectId,
    season: JSON.stringify(this.season),
  };

  axiosApiInstance
    .get(this.url + "/crops", {
      params: dataObj.parameters,
    })
    .then((result) => {
      if (result.data.success) {
        dataObj.crops = result.data.crops;
        dataObj.cropsData = result.data.data;
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

function fetchData(dataObj) {
  dataObj.parameters = {
    project_id: this.projectId,
  };
  this.loadingfarmerData = true;
  axiosApiInstance
    .get(this.url + "/get-suppliers", {
      params: dataObj.parameters,
    })
    .then((result) => {
      if (result.data.success) {
        // console.log('response - ',result.data);
        dataObj.mas = result.data.mas;
        dataObj.vas = result.data.vas;
        dataObj.farmers = result.data.farmers;
        this.loadingfarmerData = false;
        this.$emit("SupplierDataLoaded");
      }
    })
    .catch((error) => {
      console.log(error);
      this.loadingfarmerData = false;
    });
}

function onReset() {
  // Reset our form values
  (this.form.value_chain = null),
    (this.form.variety = ""),
    (this.form.gross_weight = 0),
    (this.form.net_weight = 0),
    (this.form.unit_price = 0),
    (this.form.moisture_content = ""),
    (this.form.foreign_matter = ""),
    (this.form.reg_fname = ""),
    (this.form.reg_lname = ""),
    (this.form.reg_number = ""),
    (this.form.supplier_type = null),
    (this.form.supplier = ""),
    (this.form.district = ""),
    (this.form.contact = ""),
    (this.form.amount_used = 0),
    (this.form.method = "mobile");
  this.form.receipt_number = "";
  this.form.account_name = "";
  this.form.account_number = "";
  this.form.bank = "";
  this.form.recipient_name = "";
  this.form.recipient_number = "";
  this.form.paymentInfoPresent = false;
  this.form.paymentInfoUpdated = false;
  this.form.produce_receipt = null;
  this.$bvModal.hide(this.modal_id);
  // Trick to reset/clear native browser form validation state
  this.show = false;
  this.$nextTick(() => {
    this.show = true;
  });
}
/**
 * 
 * @param {{farmer_id:string,supplier_type:string,farmer_gender:string,farmer_dob:string}} produce
 * @param {Array<{farmer_id:string,farmer_gender:string,farmer_dob:string}>} farmers 
 */
function add_farmer_data(produce,farmers){
  if(produce['supplier_type'].toLowerCase()!='farmer'){
    return {};
  }
  const farmer = farmers.find((fmr)=>fmr['id']==produce['farmer_id']);
  produce['farmer_gender'] = farmer['farmer_gender'];
  produce['farmer_dob'] = farmer['farmer_dob'];
  return produce;


}
function add_produce_record(event) {
  event.preventDefault();
  var self = this;
  this.form.amount_used = (this.form.net_weight * this.form.unit_price).toFixed(
    0
  );
  // console.log(this.$v.form);
  this.$v.form.$touch();
  if (this.$v.form.$anyError)
    return false


  this.loading = true;
  this.form.project_id = this.projectId;
  let formData = new FormData();

  this.form.gross_weight = parseFloat(this.form.gross_weight).toFixed(3);
  formData.set("data", JSON.stringify({...this.form,...add_farmer_data(this.form,this.farmers)}));
  if (this.file1) {
    formData.append("payment_mode_consent", this.file1, this.file1.name);
  }
  if (this.form.produce_receipt) {
    formData.append("produce_receipt", this.form.produce_receipt, this.form.produce_receipt.name);
  }
  const endpoint = this.form.supplier_type=="Farmer Group"?"/farmer-group-bulk":"/add-record";

  axiosApiInstance
    .post(this.url + endpoint, formData)
    .then(() => {
      this.onProdBulk();
      setTimeout(()=>{
        this.onReset();
        this.$v.form.$reset();
        this.$emit("BulkSuccessful");
      },2000);
    })
    .catch(() => {
      self.$vToastify.error(
        "failed to bulk produce, check your inputs & try again !!!"
      );
    })
    .finally(() => (this.loading = false));

}
function checkFarmerCropProp() {
  if (!$.isEmptyObject(this.selected)) {
    this.form.value_chain = this.selected.crop;
    this.form.supplier_type = "Farmer";
    this.form.supplier = this.selected.farmer;
    this.autocomplete = false;
    this.onSelectingCrop();
    this.selectSupplierType();
    this.onSupplierSelect();
    this.farmerViewOpen = true;
  }
}

function get_banks() {
  this.loadingbanks = true;
  axiosApiInstance({
    url: `${this.baseURI}/api/v3/${this.projectId}${this.url_prefix}/payments/user/banks/`,
    method: "get",
  })
    .then((res) => {
      this.banks = res.data.data;

      this.loadingbanks = false;
    })
    .catch((error) => {
      console.log(error);
      this.loadingbanks = false;
    });
  // axios.get
}
/**
 * @param {string} vaId
 * @param {{projectId:number,baseURI:string,form:{agent_name:string,agent_phone:string}}} ui
 */
function findVa(vaId, ui) {
  ui.loadingVA = true;
  const va_id = vaId.replaceAll("/", "-");
  axiosApiInstance({
    url: ui.baseURI + `/api/v3/village-agents/single/${ui.projectId}/${va_id}`
  }).then((response) => {
    const va_details = response.data;
    /**@constant {{va_name:string}} */
    const va_profile = va_details.data.va_profile;
    ui.form.agent_name = va_profile.va_name;
    ui.form.agent_phone = va_profile.va_phonenumber;
    ui.loadingVA = false;
  });
}
export {
  NameToCamelCase,
  getCurrentSeason,
  dateDifference,
  setInputFilter,
  display_payment_data,
  onContactMutation,
  handleFileUpload,
  onPaymentFieldsEdit,
  setNetWeight,
  onSelectingCrop,
  onVarietySelect,
  checkVariety,
  varietyForSelectedCrop,
  onSupplierSelect,
  selectSupplierType,
  getPaymentInfo,
  getCrops,
  checkFarmerCropProp,
  fetchData,
  onReset,
  add_produce_record,
  get_banks,
  findVa
};
