<template>
  <b-form @submit.prevent="giveInput" @reset.prevent="reset_form">
    <b-row>
      <div class="col-sm-4">
        <b-container>
          <b-form-group
            label="Supplier Type"
            label-for="supplier"
            description="Select Supplier type"
          >
            <b-form-select
              v-model="supplier_type"
              :options="supplierType"
              @change="getUsers()"
              required
            ></b-form-select> </b-form-group
          ><b-spinner
            label="Loading..."
            v-if="loadingAdvanceData == true"
          ></b-spinner>

          <b-form-group
            label="Supplier"
            label-for="supplier"
            description="Enter Supplier Name"
          >
            <b-form-input
              v-if="isAutoComplete === false"
              v-model="form.supplier"
              type="text"
              required
            ></b-form-input>
            <vue-bootstrap-typeahead
              v-if="isAutoComplete !== false"
              :data="userDetails"
              v-model="form.supplier"
              size="lg"
              :serializer="(s) => s.name"
              @hit="getFulluser()"
            ></vue-bootstrap-typeahead
            ><b-spinner label="Loading..." v-if="loading == true"></b-spinner>
          </b-form-group>

          <b-form-group
            label="Contact"
            label-for="contact"
            description="Enter Supplier's Contact"
          >
            <b-form-input
              v-model="form.contact"
              type="text"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="District"
            label-for="district"
            description="Select District"
          >
            <b-form-input
              id="district"
              v-model="form.district"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Village"
            label-for="village"
            description="Enter Supplier's Village"
          >
            <b-form-input
              v-model="form.village"
              type="text"
              required
            ></b-form-input>
          </b-form-group>

          <!-- <b-form-group
              label="Farmer Group"
              label-for="farmer_group"
              description="Select Farmer Group"
              v-show="!isMasterAgent"
            >
              <b-form-select
                v-model="form.farmer_group"
                :options="farmer_groups"
                required
              ></b-form-select>
            </b-form-group> -->
        </b-container>
      </div>

      <div class="col-sm-8 border-left">
        <div class="container">
          <b-form-group
            :label="title"
            label-for="farmer_group"
            description="Item(s)"
          >
          </b-form-group>
          <b-table
            v-show="advance_items"
            class="table"
            ref="advanceTableTest"
            :fields="fields"
            :items="advance_items"
          >
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(unit)="data">
              {{ data.item.unit }}
            </template>
            <template #cell(price)="data">
              {{ data.item.price }}
            </template>
            <template #cell(quantity)="data">
              <b-form-input
                id="quantity"
                v-model="data.item.quantity"
                type="number"
                @keyup="editQuantity(data, data.item)"
                required
              ></b-form-input>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount }}
            </template>
          </b-table>
          <b-row>
            <b-col>
              <b-button
                variant="outline-primary"
                v-analytics:click="[
                  'add_advance_item',
                  { element: 'Suppliers' },
                ]"
                v-b-modal.add-advance-item
                @click="new_item = {}"
              >
                <b-icon-plus></b-icon-plus>

                Add Item</b-button
              >
            </b-col>
          </b-row>
          <br />

          <div class="row">
            <div class="col-sm-12 float-right">
              <h5 class="text-danger">Total Amount</h5>
              <h4>{{ total_amount }}</h4>
              <b-button
                type="submit"
                variant="primary"
                v-analytics:click="['save_advance', { element: 'Suppliers' }]"
              >
                Save </b-button
              ><b-spinner
                label="Loading..."
                v-if="loadingInputSubmission == true"
              ></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </b-row>
    <add-item :advance_items="advance_items" :module="module"></add-item>
  </b-form>
</template>
<script>

import AddItem from "./subcomponents/AddItem.vue"
import {
    giveInput,editQuantity,getFulluser,
    getUsers, checkPropData,
} from "./actions"

export default {
    components: { AddItem },
    props: {
    userData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    title:{
      type:String,
      default:()=>{
        return '';
      }
    },
    module: {
      type: String,
      default: ()=>{
        return '';
      }
    },
    
  },
  data() {
    return {
      form: {
        supplier: "",
        district: "",
        contact: "",
        village: "",
        supplierType: [],
      },
      loading: false,
      total_amount: 0,
      fields: [
        { key: "name", label: "Item" },
        { key: "unit", label: "Unit" },
        { key: "price", label: "Price Per Unit" },
        { key: "quantity", label: "Quantity" },
        { key: "amount", label: "Amount" },
      ],

      districts: [{ text: "Select One", value: null }, "district"],
      supplierType: [
        { text: "Farmer", value: "farmer" },
        { text: "Village Agent", value: "va" },
        { text: "Farmer Group", value: "farmer_grup" },
      ],
      userDetails: [],
      services: [
        { text: "select Supplier type", value: null },
        "farmer",
        "Agent",
        "Farmer Group",
      ],
      // fields: ["Item", "Unit", "Price Per Unit", "Quantity", "Amount"],
      farmer_groups: [
        { text: "select Supplier type", value: null },
        "farmer",
        "Agent",
      ],
      advance_items: [],
      supplier_type: "",
      farmerId: "",
      loadingInputSubmission: false,
      isAutoComplete: true,
      loadingAdvanceData: false,
      isError: true,
    };
  },
  computed: {
    project_id() {
      return this.$store.state.project_id;
    },
  },
  mounted: function () {
    this.checkPropData();
    // this.getAdvanceBreakdown(this);
  },
  methods: {
    giveInput,editQuantity,getFulluser,
    getUsers, checkPropData,
  },
  watch: {
    advance_items: {
      handler() {
        this.total_amount = 0;
        for (const z of this.advance_items) {
          this.total_amount += z.amount;
        }
        this.$refs.advanceTableTest.refresh();
      },
      deep: true,
    },
  },
}
</script>
