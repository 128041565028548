const format_options = (el) => ({
    text: el.name,
    value: JSON.stringify({ id: el.id, name: el.name }),
});
import { required,minLength} from 'vuelidate/lib/validators'
const farmerValidations = {
    addFarmerForm:{
        farmer_name:{
        required,
        minLength:minLength(5)
        }
    }
};
export { format_options,farmerValidations }