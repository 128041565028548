export default {
  filters: {
    capitalize(value) {
      return value?value.charAt(0).toUpperCase() + value.slice(1):'-';
    },
    no_group: (gp) => {
      return gp !== undefined ? gp.name : "NA";
    },
    group_val: (gp) => {
      return gp !== undefined ? JSON.stringify(gp) : null;
    },
    fmt_meta: (meta) => {
      if (meta["type"] == "multichoice") {
        return meta["value"].join(", ");
      }
      return meta["value"];
    },
  },
};
