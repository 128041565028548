<template>
  <b-modal id="cash-payment" size="md" title="Payment" hide-footer>
    <b-row>
      <b-col>
        <b-container>
          <h5 v-show="paynow" class="text-secondary font-weight-light">
            Are you sure you want to initiate payment to
          </h5>
          <h6>
            <strong>{{ info.farmer_name }}</strong>
          </h6>
          <hr />
          <b-row>
            <b-col>
              <b-form-group
                label="Pending"
                label-for="pendingpayment"
              >
                <b-form-input
                  :value="Number(info.pending_balance).toLocaleString()"
                  type="text"
                  disabled
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Payment"
                label-for="totalpayment"
                :description="
                  currency +
                    (info.payment ? Number(info.payment).toLocaleString() : '0')
                "
              >
                <b-form-input
                  v-model="info.payment"
                  type="number"
                  :disabled="!can('create_schedule', 'payments')"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-row class="text-muted">Net balance</b-row>
              <b-row class="text-success"
                ><h4>
                  {{
                    currency +
                      Number(
                        info.pending_balance - info.payment
                      ).toLocaleString()
                  }}
                </h4></b-row
              >
            </b-col>
          </b-row>

          <b-row>
            <b-col v-show="loading">
              <b-container v-show="loading">
                <b-row class="d-flex justify-content-center mb-3">
                  <span class="font-weight-light text-muted"
                    >Processing...&nbsp;&nbsp;</span
                  >
                  <b-spinner variant="secondary" label="Loading..."></b-spinner>
                </b-row>
              </b-container>
              <b-container v-show="error">
                <b-alert class="justify-content-center" variant="danger">Payment Failed!!!</b-alert>
                    
              </b-container>
              <b-container v-show="success">
                <b-alert class="justify-content-center" variant="success" show>Successful!!</b-alert>
              </b-container>
            </b-col>
          </b-row>
          <b-row v-show="payment_not_started && paynow && !loading">

          <b-col>
            <b-button

              :disabled="!can('approve', 'payments')"
              variant="success"
              class="col"
              @click.prevent="pay_farmer"
              >
              <b-icon-check></b-icon-check>
              Record Cash Payment</b-button
            >
            <br/>
            
          </b-col>
        </b-row>
        </b-container>
      </b-col>

    </b-row>
  </b-modal>
</template>
<script>
import { bus } from "@/main.js";
import { pay_farmer,} from "./actions.js";
import { can } from "@/modules/auth/utils.js";

export default {
  data() {
    return {
      info: { farmer_name: "", total_amount: 0, farmer_id: "" },
      currency: "UGX.",
      loading: false,
      error: false,
      payment_not_started: true,
      success: false,
      paynow: false,
      methods: [
        {value:"mobile", text:"Mobile"}, 
        {value:"bank", text:"Bank"}
      ],
      select_method: "mobile",
      banks: [],
      select_bank:'',
      verified:false,
      waited:1,
      pay_now:false,
      loadingbanks:false,
    };
  },
  computed:{
    url(){
      return `${process.env.VUE_APP_BACKEND}/api/v3/${this.$store.state.project_id}${this.$store.state.url_prefix}`;
    },
  },
  methods: {
    can,
    pay_farmer,
  },
  created() {
    bus.$on("pay-cash", (payload) => {
      this.info={};
      this.info = payload;
      this.payment_not_started = true;
      this.error = false;
      this.success = false;
      this.loading = false;

      this.paynow=true
      this.verified=false;

      this.waited=1;
      this.$bvModal.show("cash-payment");

    });
  },
};
</script>

