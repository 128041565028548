<template>
  <b-container id="dashboard">
   
    <b-row>
      <div class="col-8">
        <b-row>
          <b-col>
            <div class="text-muted">Produce Bulked</div>
          </b-col>
          <b-col>
            <b-dropdown
              :text="season"
              class="m-md-2 float-right"
              variant="light"
            >
              <b-dropdown-item
                v-for="(season, idx) in seasons"
                :key="'d' + idx"
                @click="getByDate(season)"
                >{{ season }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
        <div>
          <dot-flash loaderClass="loader-primary" :status="chartldr.status">
          </dot-flash>
          <bar-chart
            class="container"
            :series="series"
            :chartOptions="chartOptions"
            height="100"
            v-show="chartldr.active"
          ></bar-chart>
        </div>
          <div class="my-5">
          <dot-flash loaderClass="loader-primary" :status="chartldr.status">
          </dot-flash>
          <bar-chart
            v-show="false"
            class="container"
            :chartOptions="revenueChartOptions"
            height="100"
           
          ></bar-chart>
        </div>
        <div style="min-height:100px;" class="position-relative bg-light">
          <b-table
            sticky-header
            :items="items"
            head-variant="light"
            show-empty
            :fields="fields"
           
          >
            <template #empty>
              <h4>Farmer has no data.</h4>
            </template>
          </b-table>
          <ripple :status="entriesldr.status" class="position-absolute" style="top:200px;"></ripple>
        </div>
      </div>
      <div class="col">
        <div class=" text-left mb-5"  >

        <payment :item="item"></payment>
        </div>
      
        <div class="tile advance text-left mb-5" show >
          <h6 class="text-center-">Advances Due</h6>
          <h2 class="text-center-">UGX {{ advances }}</h2>
        </div>

        <br />

      </div>
    </b-row>
  </b-container>
</template>
<script>
import Payment from "../payment/Payment.vue";
import BarChart from "./subcomponents/BarGraph.vue";
import { series as cseries, chartOptions as coptions } from "./defaults.js";
import {
  get_by_date,
  get_produce_available,
  get_produce_entries,
} from "./api.js";
import { get_labels, get_values, FSEASONS } from "./utils.js";
import {
  // get_produce_harvested,
  get_advances,
  get_average_acreage,
  // get_fp_orders,
} from "./actions.js";
import { to_tonnes } from "@/modules/utils.js";
import DotFlash from "../../../loaders/DotFlash.vue";
import { loader } from "../../../loaders";
import Ripple from "../../../loaders/Ripple.vue";
export default {
  components: { BarChart, Payment, DotFlash, Ripple },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          farmer_id: "",
        };
      },
    },
  },
  computed: {
    projectId() {
      return this.$store.state.project_id;
    },
  },
  data() {
    // let fields = ['crop','planting_date','exp_yield','harvest_date'];
    let fields = [
      { key: "value_chain", label: "Crop" },
      { key: "quantity_supplied", label: "Quantity" },
      { key: "amount_used", label: "Amount" },
      { key: "date_received", label: "Date" },
    ];
    let items = [];
    let series = cseries;
    let revenueChartOptions = {
      chart: {
          type: 'area',
          height: 350
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        
        title: {
          text: 'Revenue from Grain pulse',
          align: 'left',
          style: {
            fontSize: '14px'
          }
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          tickAmount: 4,
          floating: false,
        
          labels: {
            style: {
              colors: '#8e8da4',
            },
            offsetY: -7,
            offsetX: 0,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false
          }
        },
        fill: {
          opacity: 0.5
        },
    };
    let chartOptions = coptions;
    let chartldr = new loader();
    let entriesldr = new loader();
    return {
      chartldr,
      entriesldr,
      fields,
      items,
      series,
      revenueChartOptions,
      advances: "...",
      // average_acreage: 0,
      chartOptions,
      season: "All Seasons",
      seasons: [
        "This Season",
        "Last Season",
        "All Seasons",
        // "Single Date",
        // "Date Range",
      ],
    };
  },
  mounted() {
    // this.get_produce_harvested(this.projectId, this.item.farmer_id);
    this.get_advances(this.projectId, this.item.farmer_id);
    // this.get_average_acreage(this.projectId, this.item.farmer_id);
    // this.get_fp_orders(this.projectId, this.item.farmer_id);
    this.getProduceAvailable();
    this.getProduceEntries();
  },
  methods: {
    // get_produce_harvested,
    get_advances,
    get_average_acreage,
    // get_fp_orders,
    getByDate(season) {
      this.season=season;
      if (this.season == "All Seasons") {
        this.getProduceAvailable();
        this.getProduceEntries();
      } else {
        const lambda = FSEASONS[this.season];
        this.chartldr.reload();
        this.entriesldr.reload();
        get_by_date(this.projectId, this.item.farmer_id, lambda)
          .then((data) => {
            this.recvAvailable(data.available);
            this.recvEntries(data.entries);
          })
          .catch(() => {
            this.chartldr.fail();
            this.entriesldr.fail();
          });
      }
    },
    recvAvailable(data) {
      this.chartldr.done();
      get_values(data).then((vals) => {
        this.series = [{ name: "Tonnes", data: vals.map(to_tonnes) }];
      });
      get_labels(data).then((labels) => {
        this.chartOptions = {
          ...coptions,
          xaxis: {
            categories: labels,
            title: { text: "Produce Bulked" },
          },
        };
      });
    },
    recvEntries(data) {
      this.items = data;
      this.entriesldr.done();
    },
    getProduceAvailable() {
      this.chartldr.reload();
      get_produce_available(this.projectId, this.item.farmer_id)
        .then((data) => {
          this.recvAvailable(data);
        })
        .catch(() => {
          this.chartldr.fail();
        });
    },
    getProduceEntries() {
      this.entriesldr.reload();
      get_produce_entries(this.projectId, this.item.farmer_id)
        .then((data) => {
          this.recvEntries(data);
        })
        .catch(() => {
          this.entriesldr.fail();
        });
    },
  },
};
</script>
