import { axiosApiInst } from "@/plugins/axios";
function add_advance() {
  let form = this.form;
  axiosApiInst({
    url: process.env.VUE_APP_BACKEND + "/add_advance_route",
    method: "post",
    data: JSON.stringify(form),
  })
    .then((res) => {
      console.log(res.status);
    })
    .catch((error) => {
      console.log(error);
    });
}
function giveAdvance(payload) {
  // console.log(dataObject);
  this.loadingInputSubmission = true;
  axiosApiInst({
    url: process.env.VUE_APP_BACKEND + "/advances/give",
    method: "post",
    data: payload,
  })
    .then((response) => {
      this.loadingInputSubmission = false;
      if (response.data.success === true) {
        this.$vToastify.success(response.data.message);
        this.$emit("AdditionSuccess");
        this.$bvModal.hide(this.modal_id);
      } else {
        this.$vToastify.error(response.data.message);
        // alert(response.data.message);
      }
    })
    .catch((error) => {
      this.loadingInputSubmission = false;
      this.$vToastify.error(error.response.data.message);
    });
}
function reset_form() {
  this.form = {
    supplier: "",
  };
}
function format_number(number) {
  return number.toLocaleString();
}
export { add_advance, format_number, reset_form, giveAdvance };
