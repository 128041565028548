var _ = require("lodash");
class Farm{
  /**
   * @param {{name:string,centroid:Array<number>,coordinates:Array<Array<number>>,acreage:number}}
   */
  constructor({name,centroid,coordinates,acreage}){
    
    this.name = name;
    this.centroid =centroid.reverse();
    this.coordinates = coordinates.map(el=>el.reverse());
    this.acreage = acreage;
  }
}
class Shape{
    /**
     * @param {Array<number>} points
     */
    constructor(points){
        this.coordinates = points;
    }
    get centroid(){
        const x_list = this.coordinates.map(el=>el[0]);
        const y_list = this.coordinates.map(el=>el[1]);
        const x_mean = _.mean(x_list);
        const y_mean = _.mean(y_list);
        return [x_mean,y_mean];
    }
}
export {Farm,Shape}